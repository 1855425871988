import adsConfig from "@data/pagesConfigAds";
import { pathDictionary, sectionDictionary } from "@helpers/helperAds";
import { getAdsRespApi } from "@lib/api/ads/getAdsRespApi";
import { uuidv4 } from "@lib/helpers";
import { checkCookies, getCookie } from "cookies-next";

export const getAdsCreateGroup = (pathName: string): AdsGroupType[] => {
  const product = pathDictionary(pathName); // * hotels, flight, cars etc
  const section = sectionDictionary(pathName); // * home, search-detail

  const productConfig = adsConfig.find((f) => f.product == product);
  const sectionConfig = productConfig?.sections?.find((f) => f.name == section);

  const spaces = sectionConfig?.spaces;
  const groupConfig: AdsGroupType[] = [];

  if (spaces) {
    for (const space of spaces) {
      for (const spaceConfig of space?.config || []) {
        const originalType = spaceConfig.type;
        const originalPriority = spaceConfig.priority;
        const typeAndPriority = `${spaceConfig.type.join("_")}_${
          spaceConfig.priority
        }`;
        const findGroupIndex = groupConfig.findIndex(
          (f) => f.typeAndPriority == typeAndPriority
        );

        if (findGroupIndex > -1) {
          const aux = groupConfig[findGroupIndex];
          groupConfig[findGroupIndex] = {
            ...aux,
            qty: aux.qty + 1,
          };
        } else {
          groupConfig.push({
            typeAndPriority,
            qty: 1,
            type: originalType,
            priority: originalPriority,
          });
        }
      }
    }
  }
  return groupConfig;
};
export const getRequestAds = (
  orgId: number,
  pathName: string,
  checkinDate: string,
  adsGroupReq: AdsGroupType[],
  req: any,
  res: any
) => {
  const data = [] as AdsRequestV2Type[];
  const home = [
    "/",
    "/home",
    "/holidaystays",
    "cruises",
    "/cars",
    "/flight",
    "/transfers",
    "/activities",
    "/weeks",
    "/resorts",
    "/stays",
    "/dreamtrips",
  ];
  const currency = checkCookies("currency_code", { req, res })
    ? String(getCookie("currency_code", { req, res }))
    : "USD";
  const lang = checkCookies("lan_code", { req, res })
    ? String(getCookie("lan_code", { req, res }))
    : "en-US";
  const dateStart = checkinDate ?? getTodayDate();

  let latLong = {
    searchLat: 0,
    searchLong: 0,
  };

  // if (checkCookies("userGeolocation") && home.includes(pathName)) {
  //   const latData = JSON.parse(String(getCookie("userGeolocation")));
  //   if (latData?.lat && latData?.long) {
  //     latLong = {
  //       searchLat: latData?.lat || 0,
  //       searchLong: latData?.long || 0,
  //     };
  //   }
  // }

  if (
    checkCookies("searchLatitude", { req, res }) &&
    checkCookies("searchLongitude", { req, res }) &&
    !home.includes(pathName)
  ) {
    const lat = Number(getCookie("searchLatitude", { req, res }));
    const lon = Number(getCookie("searchLongitude", { req, res }));

    if (lat && lon) {
      latLong = {
        searchLat: lat || 0,
        searchLong: lon || 0,
      };
    }
  }

  console.log("AdslatLong", latLong);
  for (const group of adsGroupReq) {
    // los banners se ejecutan en otro metodo
    if (!group.type.includes("banner")) {
      data.push({
        ...latLong,
        currency,
        dateStart,
        from: "context",
        lang,
        orderBy: "",
        orgId: orgId,
        pathName,
        priority: group.priority,
        qty: group.qty,
        type: group.type,
      });
    }
  }
  return data;
};

export const getTodayDate = () => {
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = String(today.getMonth() + 1); // Months start at 0!
  let dd = String(today.getDate());

  if (Number(dd) < 10) dd = "0" + dd;
  if (Number(mm) < 10) mm = "0" + mm;

  const formattedToday = `${yyyy}-${mm}-${dd}`;

  return formattedToday;
};

export const adsParallelCalls = async (
  pathName: string,
  orgId: number,
  checkinDate: string
) => {
  // const promises = [];
  // const groupsAds = getAdsCreateGroup(pathName);
  // const groupsAdsRequest = getRequestAds(orgId, pathName, groupsAds);

  // for (const req of groupsAdsRequest) {
  //   promises.push(getAdsData(req));
  // }

  // const data = await Promise.all(promises);
  // console.log("🚀 ~ file: ads.service.ts:115 ~ adsParallelCalls ~ data:", data);
  pathName = pathName == "/home" ? pathName : pathName.replace("/home", "");
  const data = await getAdsRespApi({
    pathName,
    orgId,
    checkinDate,
  });
  console.log("groupsAdsRequest all", data);

  // let dataCard = [] as MainCardType[];
  // for (const card of data) {
  //   console.log("groupsAdsRequest card", card);
  //   dataCard = [...dataCard, ...card];
  // }

  // console.log("dataCard servc", dataCard);
  return data;
};

export const getAdsDataExtern = (data: AdsResponseType) => {
  //console.log("getAdsDataExtern", data);
  const jsonData = data as AdsResponseType;
  const dataCard = [] as MainCardType[];
  const petitionType = jsonData?.petitionType;
  const petitionTypeStr = jsonData?.petitionType?.join("_");
  const priority = jsonData?.priority;
  for (const card of jsonData?.cards) {
    const uuid = uuidv4();
    dataCard.push({
      ...card,
      petitionType,
      petitionTypeStr,
      priority,
      uuid,
    });
  }
  return dataCard;
};

// async function getAdsData(data: AdsRequestV2Type) {
//   const response = await fetch("/api/flights/getAdsData", {
//     method: "POST",
//     body: JSON.stringify(data),
//     headers: { "Content-type": "application/json; charset=UTF-8" },
//   });
//   const jsonData = (await response.json()) as AdsResponseType;
//   const dataCard = [] as MainCardType[];
//   const petitionType = jsonData?.petitionType;
//   const petitionTypeStr = jsonData?.petitionType?.join("_");
//   const priority = jsonData?.priority;
//   for (const card of jsonData?.cards) {
//     const uuid = uuidv4();
//     dataCard.push({
//       ...card,
//       petitionType,
//       petitionTypeStr,
//       priority,
//       uuid,
//     });
//   }
//   return dataCard;
// }

// * podria ser /, search-detail, checkout etc
export const getCurrentSection = (path: string) => {
  let splitPath = path.split("/");
  if (splitPath.includes("search-detail")) {
    return "search-detail";
  }
  if (splitPath.includes("search-details")) {
    return "search-details";
  }
  if (splitPath.length <= 2) {
    return "/";
  }
  return splitPath[1];
};

// * return spaces config
export const getSpacesBySection = (path: string, productType: string) => {
  const currentSection = getCurrentSection(path);
  const productConfig = adsConfig.find((f) => f.product == productType);
  const sectionConfig = productConfig?.sections?.find(
    (f) => f.name == currentSection
  );
  return sectionConfig?.spaces;
};

export const getSpacesSizeBySection = (
  path: string,
  productType: string,
  spaceName: string
) => {
  const currentSection = getCurrentSection(path);
  const productConfig = adsConfig.find(
    (f) => f.product == productType
  ) as AdObjectType;
  const sectionConfig = productConfig?.sections?.find(
    (f) => f.name == currentSection
  );
  const space = sectionConfig?.spaces.find((f) => f.name == spaceName);
  const config = space?.config?.find((f) => f);
  if (config?.size) {
    return config?.size;
  }
  return "normal";
};
