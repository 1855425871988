import { uuidv4 } from "@lib/helpers";
import { create } from "zustand";
type CustomerPGV2Type = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  address: {
    country_code: string;
  };
};

type InitSavedCardPGV2Type = {
  amount: number;
  amount_to_capture: number;
  currency: string;
  email: string;
  country_code: string;
};

type InitPaymentPGV2Type = {
  amount: number;
  amount_to_capture: number;
  currency: string;
  email: string;
  country_code: string;
  order_id: string;
};

interface PaymentMethodsStoreType {
  travelerCustomer: CustomerPGV2Type | null;
  customer: CustomerPGV2Type | null;
  paymentInfo: InitSavedCardPGV2Type | null;
  initPaymentData: InitPaymentPGV2Type | null;
  initPaymentDataCC: InitPaymentPGV2Type | null;
  isCustomerLoaded: boolean;
  loadCards: string;
  cards: PgCardsSavedType[];
  invoiceItem: ItemInvoicePgType[];
  rulesAndRestrictions: ItemTermsConditionsInvoicePgType | null;
  cancelationTerms: ItemTermsConditionsInvoicePgType | null;
  loadSaveNewCardForm: boolean;
  hideBtnBook: boolean;
  hideCardRequired: boolean;
  //priceAmount: number;
  cc_required: boolean;
  extraMetadataHLTPriceHange: RequestMultiBookExtraMetadataType | null;
  paymentIdGenerate: string;
  setRulesAndRestrictions: (value: ItemTermsConditionsInvoicePgType) => void;
  setCancelationTerms: (value: ItemTermsConditionsInvoicePgType) => void;
  setInvoiceItem: (value: ItemInvoicePgType[]) => void;
  setPaymentIdGenerate: (value: string) => void;
  setCc_required: (value: boolean) => void;
  //setPriceAmount: (value: number) => void;
  setTravelerCustomer: (value: CustomerPGV2Type) => void;
  setCustomer: (value: CustomerPGV2Type | null) => void;
  setIsCustomerLoaded: (value: boolean) => void;
  setPaymentInfo: (value: InitSavedCardPGV2Type | null) => void;
  setCards: (value: any[]) => void;
  fetchLoadCards: () => void;
  setLoadSaveNewCardForm: (value: boolean) => void;
  setInitPaymentData: (value: InitPaymentPGV2Type | null) => void;
  setInitPaymentDataCC: (value: InitPaymentPGV2Type | null) => void;
  setHideBtnBook: (value: boolean) => void;
  setHideCardRequired: (value: boolean) => void;
  setExtraMetadataHLTPriceHange: (
    data: RequestMultiBookExtraMetadataType | null
  ) => void;
  clearDataPgv2: () => void;
}

const initData = {
  travelerCustomer: null,
  customer: null,
  isCustomerLoaded: false,
  paymentInfo: null,
  initPaymentData: null,
  initPaymentDataCC: null,
  loadCards: "",
  cards: [],
  loadSaveNewCardForm: false,
  hideBtnBook: false,
  hideCardRequired: false,
  //priceAmount: 0,
  cc_required: true,
  extraMetadataHLTPriceHange: null,
  paymentIdGenerate: "",
  invoiceItem: [],
  rulesAndRestrictions: null,
  cancelationTerms: null,
};

export const paymentMethodsPgV2Store = create<PaymentMethodsStoreType>(
  (set) => ({
    ...initData,
    setRulesAndRestrictions: (value: ItemTermsConditionsInvoicePgType) =>
      set({ rulesAndRestrictions: value }),
    setCancelationTerms: (value: ItemTermsConditionsInvoicePgType) =>
      set({ cancelationTerms: value }),
    setInvoiceItem: (value: ItemInvoicePgType[]) => set({ invoiceItem: value }),
    clearDataPgv2: () => set({ ...initData }),
    setPaymentIdGenerate: (value: string) => set({ paymentIdGenerate: value }),
    setCc_required: (value) => set({ cc_required: value }),
    // setPriceAmount: (value) => set({ priceAmount: value }),
    setTravelerCustomer: (value) => set({ travelerCustomer: value }),
    setCustomer: (value) => set({ customer: value }),
    setIsCustomerLoaded: (value) => set({ isCustomerLoaded: value }),
    setPaymentInfo: (value) => set({ paymentInfo: value }),
    setCards: (value) => set({ cards: value }),
    fetchLoadCards: () => set({ loadCards: uuidv4() }),
    setLoadSaveNewCardForm: (value) => set({ loadSaveNewCardForm: value }),
    setInitPaymentData: (value) => set({ initPaymentData: value }),
    setInitPaymentDataCC: (value) => set({ initPaymentDataCC: value }),
    setHideBtnBook: (value) => set({ hideBtnBook: value }),
    setHideCardRequired: (value) => set({ hideCardRequired: value }),
    setExtraMetadataHLTPriceHange: (value) =>
      set({ extraMetadataHLTPriceHange: value }),
  })
);
