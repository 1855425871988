const totalAds = 50;
const getDreamtripsConfig = (
  config = {
    type: ["dreamtrips"],
    priority: "revenue",
  }
) => {
  const data = [];
  for (let i = 0; i < totalAds; i++) {
    data.push(config);
  }

  return data;
};
const config = {
  product: "dreamtrips",
  sections: [
    {
      name: "/",
      spaces: [
        {
          name: "banner",
          config: [
            {
              type: ["banner"],
              priority: "revenue",
            },
            {
              type: ["banner"],
              priority: "revenue",
            },
            {
              type: ["banner"],
              priority: "revenue",
            },
            {
              type: ["banner"],
              priority: "revenue",
            },
            {
              type: ["banner"],
              priority: "revenue",
            },
          ],
        },
        {
          name: "space1",
          config: getDreamtripsConfig({
            type: ["dreamtrips"],
            priority: "revenue",
          }),
        },
        {
          name: "space2",
          config: getDreamtripsConfig({
            type: ["dreambreaks"],
            priority: "revenue",
          }),
        },
      ],
    },
  ],
};

export default config;
