import axios from "axios";

type ReleasePayload = {
  residency: string;
  search_type: string;
  reservationToken: string;
  mainPath: string;
};

export const applyRelease = async (
  request: ReleasePayload
): Promise<WeeksReleaseResponse | null> => {
  try {
    const response = await axios.post(`/api/weeks/release`, request);
    return response.data.data;
  } catch (error) {
    console.info(error);
    return null;
  }
};
