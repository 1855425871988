import hotelsConfig from "@data/pagesConfigAds/hotels";
import flightConfig from "@data/pagesConfigAds/flights";
import carsConfig from "@data/pagesConfigAds/cars";
import holidaystaysConfig from "@data/pagesConfigAds/holidaystays";
import activitiesConfig from "@data/pagesConfigAds/activities";
import transfersConfig from "@data/pagesConfigAds/transfers";
import cruisesConfig from "@data/pagesConfigAds/cruises";
import weeksConfig from "@data/pagesConfigAds/weeks";
import resortsConfig from "@data/pagesConfigAds/resorts";
import staysConfig from "@data/pagesConfigAds/stays";
import dreamtripsConfig from "@data/pagesConfigAds/dreamtrips";

const config = [
  hotelsConfig,
  flightConfig,
  carsConfig,
  holidaystaysConfig,
  activitiesConfig,
  transfersConfig,
  cruisesConfig,
  weeksConfig,
  resortsConfig,
  staysConfig,
  dreamtripsConfig,
];

export default config;
