import { getLangCodeFull, getCurrencyCode, getSymb } from "./helper";
type TypeCurrencyOptions = {
  maxDigits?: number;
  simbol?: boolean;
  minDigits?: number;
};
export const getCurrencyByLanguage = (
  price: number,
  { maxDigits = 2, simbol = true, minDigits = 0 }: TypeCurrencyOptions = {}
) => {
  try {
    let formatFunction = new Intl.NumberFormat(getLangCodeFull(), {
      style: "currency",
      currency: getCurrencyCode(),
      minimumFractionDigits: minDigits,
      maximumFractionDigits: maxDigits,
    });
    const formattedPrice = formatFunction.format(price);
    const currencySymbol =
      formatFunction
        .formatToParts(price)
        .find((part) => part.type === "currency")?.value || "";
    const adjustedPrice = formattedPrice.replace(currencySymbol, "").trim();
    if (simbol) {
      return `${currencySymbol}${adjustedPrice}`;
    }
    return `${adjustedPrice}`;
  } catch {
    if (!simbol) return price;
    return getSymb() + " " + price;
  }
};

export const currencyFormatting = (
  amount: number,
  withDecimals = false,
  withCurrency = true
) => {
  const mainCurrency = getSymb();
  const amountFormated = Number(
    amount % 1 > 0 ? amount.toFixed(2) : amount.toFixed(0)
  );

  const finalAmount = !withDecimals
    ? getCurrencyByLanguage(Math.round(Number(amountFormated)), {
        simbol: false,
      })
    : getCurrencyByLanguage(Number(amountFormated), { simbol: false });

  if (!withCurrency) {
    return `${finalAmount}`;
  }
  return `${mainCurrency}${finalAmount}`;
};

export const getPriceInDays = (price: number, daysNum: number) => {
  const priceInDays = price / daysNum;

  return getCurrencyByLanguage(Number(priceInDays.toFixed(2)), {
    simbol: false,
  });
};
