const config = {
  product: "weeks",
  sections: [
    {
      name: "/",
      spaces: [
        {
          name: "banner",
          config: [
            {
              type: ["banner"],
              priority: "revenue",
            },
            {
              type: ["banner"],
              priority: "revenue",
            },
            {
              type: ["banner"],
              priority: "revenue",
            },
            {
              type: ["banner"],
              priority: "revenue",
            },
            {
              type: ["banner"],
              priority: "revenue",
            },
          ],
        },
        {
          name: "space1",
          config: [
            {
              type: ["resort"],
              priority: "revenue",
            },
            {
              type: ["resort"],
              priority: "revenue",
            },
            {
              type: ["resort"],
              priority: "revenue",
            },
            {
              type: ["resort"],
              priority: "revenue",
            },
          ],
        },
      ],
    },
  ],
};

export default config;
