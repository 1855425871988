import axios from "axios";

export const getSupplierpg = async (
  productType?: Wallet_Product_Type,
  pg_supplier_id?: string
): Promise<SupplierPgType[]> => {
  try {
    const response = await axios.post("/api/booking/getSupplierpg", {
      pg_product_type: productType || "",
      pg_supplier_id: pg_supplier_id || "",
    });
    return response.data.data.data;
  } catch (error) {
    console.info(error);

    return [];
  }
};

export const generatePgToken = async (
  data: GeneratePgTokenReqType
): Promise<GeneratePgTokenResType | null> => {
  try {
    const response = await axios.post("/api/booking/generatePgToken", {
      ...data,
    });
    return response.data;
  } catch (error) {
    console.info(error);

    return null;
  }
};

export const getUserCreditCards = async (
  data: getUserCreditCardsReqType
): Promise<UserCreditCardType[]> => {
  try {
    const response = await axios.post("/api/booking/getUserCreditCards", {
      ...data,
    });
    return response.data.data.data;
  } catch (error) {
    console.info(error);

    return [];
  }
};
export const setDefaultCard = async (
  data: setCreditCardsReqType
): Promise<setCreditCardsResType> => {
  try {
    const response = await axios.post("/api/booking/setDefaultCard", {
      ...data,
    });
    //return response.data.data.data;
    return response.data.data;
  } catch (error: any) {
    console.info(error);

    return error;
  }
};
export const deleteCardSelected = async (
  data: setCreditCardsDeleteReqType
): Promise<setCreditCardsResType> => {
  try {
    const response = await axios.post("/api/booking/deletecard", {
      ...data,
    });
    //return response.data.data.data;
    return response.data.data;
  } catch (error: any) {
    console.info(error);

    return error;
  }
};
