const config = {
  product: "resorts",
  sections: [
    {
      name: "/",
      spaces: [
        {
          name: "banner",
          config: [
            {
              type: ["banner"],
              priority: "revenue",
            },
            {
              type: ["banner"],
              priority: "revenue",
            },
            {
              type: ["banner"],
              priority: "revenue",
            },
            {
              type: ["banner"],
              priority: "revenue",
            },
            {
              type: ["banner"],
              priority: "revenue",
            },
          ],
        },
        {
          name: "space1",
          config: [
            {
              type: ["resort"],
              priority: "revenue",
            },
            {
              type: ["resort"],
              priority: "revenue",
            },
            {
              type: ["resort"],
              priority: "revenue",
            },
            {
              type: ["resort"],
              priority: "revenue",
            },
          ],
        },
        {
          name: "space2",
          config: [
            {
              type: ["destinations"],
              priority: "revenue",
            },
            {
              type: ["destinations"],
              priority: "revenue",
            },
            {
              type: ["destinations"],
              priority: "revenue",
            },
            {
              type: ["destinations"],
              priority: "revenue",
            },
            {
              type: ["destinations"],
              priority: "revenue",
            },
            {
              type: ["destinations"],
              priority: "revenue",
            },
          ],
        },
        {
          name: "space3",
          config: [
            {
              type: ["ctc"],
              priority: "revenue",
            },
            {
              type: ["ctc"],
              priority: "revenue",
            },
            {
              type: ["ctc"],
              priority: "revenue",
            },
            {
              type: ["ctc"],
              priority: "revenue",
            },
            {
              type: ["ctc"],
              priority: "revenue",
            },
            {
              type: ["ctc"],
              priority: "revenue",
            },
            {
              type: ["ctc"],
              priority: "revenue",
            },
            {
              type: ["ctc"],
              priority: "revenue",
            },
          ],
        },
        {
          name: "space4",
          config: [
            {
              type: ["hotel"],
              priority: "revenue",
            },
            {
              type: ["hotel"],
              priority: "revenue",
            },
            {
              type: ["hotel"],
              priority: "revenue",
            },
            {
              type: ["hotel"],
              priority: "revenue",
            },
            {
              type: ["hotel"],
              priority: "revenue",
            },
            {
              type: ["hotel"],
              priority: "revenue",
            },
          ],
        },
        {
          name: "space5",
          config: [
            {
              type: ["promotions"],
              priority: "revenue",
            },
            {
              type: ["promotions"],
              priority: "revenue",
            },
            {
              type: ["promotions"],
              priority: "revenue",
            },
            {
              type: ["promotions"],
              priority: "revenue",
            },
            {
              type: ["promotions"],
              priority: "revenue",
            },
            {
              type: ["promotions"],
              priority: "revenue",
            },
            {
              type: ["promotions"],
              priority: "revenue",
            },
            {
              type: ["promotions"],
              priority: "revenue",
            },
          ],
        },
      ],
    },
    {
      name: "search-detail",
      spaces: [
        {
          name: "space1",
          config: [
            {
              type: ["resort"],
              priority: "revenue",
              size: "large",
            },
          ],
        },
        {
          name: "space2",
          config: [
            {
              type: ["resort"],
              priority: "revenue",
              size: "large",
            },
          ],
        },
        {
          name: "space3",
          config: [
            {
              type: ["resort"],
              priority: "revenue",
              size: "large",
            },
          ],
        },
      ],
    },
  ],
};

export default config;
