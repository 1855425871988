import { create } from "zustand";

interface AdsState {
  adsData: MainCardType[];
  adsDataUsed: string[];
  adsBannerData: MainCardType[] | null;
  filterThemeApplied: string[];
  filterRegionApplied: string[];
  filterTripLengthApplied: string[];
  setAdsBannerData: (val: MainCardType[]) => void;
  setAdsData: (val: MainCardType[]) => void;
  setAdsUsedData: (val: string) => void;
  clearAdsUsedData: () => void;
  clearAdsData: () => void;
  setFilterThemeApplied: (
    val: string,
    type: "theme" | "region" | "tripLength"
  ) => void;
}

export const useAdsStore = create<AdsState>((set, get) => ({
  adsData: [],
  adsBannerData: null,
  adsDataUsed: [],
  filterThemeApplied: [],
  filterRegionApplied: [],
  filterTripLengthApplied: [],
  setAdsData: (value: MainCardType[]) => {
    set({ adsData: value });
  },
  setAdsBannerData: (value: MainCardType[]) => {
    set({ adsBannerData: value });
  },
  setAdsUsedData: (value: string) => {
    const { adsDataUsed } = get();
    adsDataUsed.push(value);
    set({ adsDataUsed });
  },
  clearAdsUsedData: () => {
    set({ adsDataUsed: [] });
  },
  clearAdsData: () => {
    set({ adsData: [] });
  },
  setFilterThemeApplied: (
    value: string,
    type: "theme" | "region" | "tripLength"
  ) => {
    const { filterThemeApplied, filterRegionApplied, filterTripLengthApplied } =
      get();
    let data = [] as string[];
    if (type === "theme") {
      data = [...filterThemeApplied];
    } else if (type === "region") {
      data = [...filterRegionApplied];
    } else if (type === "tripLength") {
      data = [...filterTripLengthApplied];
    }
    const index = data.indexOf(value);
    if (index > -1) {
      data.splice(index, 1);
    } else {
      data.push(value);
    }

    if (!value) {
      data = [];
    }

    if (type === "theme") {
      set({ filterThemeApplied: data });
    } else if (type === "region") {
      set({ filterRegionApplied: data });
    } else if (type === "tripLength") {
      set({ filterTripLengthApplied: data });
    }
  },
}));
