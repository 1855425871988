import { RoomDefaultNotFound, defaultNotFound } from "@data/imagesNotFound";
import { getDatesFromRange } from "./helper";
import {
  checkCookies,
  getCookie,
  removeCookies,
  setCookies,
} from "cookies-next";
import { IMAGE_PATH } from "./config";
import moment from "moment";
import { addDays } from "date-fns";
const IMG_NOT_FOUND = "/images/modalSignInSignUp/photoNotFound.png";
import adsConfig from "@data/pagesConfigAds";
import { getCurrentSection } from "@services/ads.service";

export const MATCH_URL_SPECIAL_PARAMS_WITH_ANGLE = /<<([^>]*)>>/g; // get from string every word  inside << >>
export const MATCH_ANGLE_BRACKETS_TO_REMOVE_KEY = /[<>]/g; // remove <> from string
export const MATCH_ANGLE_BRACKETS_TO_REMOVE_URL = /<<[^>]*>>/g; // remove from url special params
export const MATCH_SLACH_AMPERSON = /\/&/g; // change /& to ?

export const getAdTypeByPath = (path: string) => {
  switch (path) {
    case "/":
      return "hotel";
      break;
    case "/flight":
      return "flight";
      break;
    case "/cars":
      return "car";
      break;
    case "/weeks":
      return "resort";
      break;
    case "/cruises":
      return "cruises";
      break;
    case "/resorts":
      return "resort";
      break;
    case "/stays":
      return "stays";
      break;
    case "/dreamtrips":
      return "dreamtrips";
      break;
    default:
      let splitPath = path.split("/");
      return String(splitPath[1]);
      break;
  }
};

export const pathDictionary = (path: string) => {
  // console.log("🚀 ~ pathDictionary ~ path:", path);
  if (["/", "/home"].includes(path)) {
    return "hotels";
  }
  path = path.replace("/home", "");
  //console.log("🚀 ~ pathDictionary ~ path:", path);
  if (path === "/search-detail") {
    return "hotels";
  }

  let splitPath = path.split("/");
  return String(splitPath[1]);
};

export const sectionDictionary = (path: string) => {
  console.log("🚀 ~ sectionDictionary ~ path:", path);
  if (["/", "/home"].includes(path)) {
    return "/";
  }
  if (path === "/search-detail") {
    return "search-detail";
  }

  let splitPath = path.split("/");

  if (splitPath.length === 2) {
    return "/";
  }

  if (splitPath.length > 2) {
    return splitPath[2];
  }

  return "/";
};

export const getCurrentDate = () => {
  const current = new Date();
  const month =
    String(current.getMonth() + 1).length == 1
      ? `0${String(current.getMonth() + 1)}`
      : String(current.getMonth() + 1);
  const day =
    String(current.getDate()).length == 1
      ? `0${String(current.getDate())}`
      : String(current.getDate());

  return `${current.getFullYear()}-${month}-${day}`;
};

export const getDateStart = (startDateUrl: string | string[] | undefined) => {
  const searchDate =
    startDateUrl !== undefined
      ? getDatesFromRange(String(startDateUrl)).date1
      : undefined;

  if (searchDate) return searchDate;

  return getCurrentDate();
};

export const defaultImgSelector = (type: string) => {
  switch (type) {
    case "hotel":
      return "/images/notFound/HotelMain-01.jpg";
      break;
    case "car":
      return "/images/notFound/CarMain-01.jpg";
      break;
    case "flight":
      return "/images/general/no-img-found.png";
      break;

    default:
      return defaultNotFound;
  }
};

export const getTextStringOrByLang = (
  textValue: string | UrlLabelType | undefined
) => {
  let lanCode = String(getCookie("lan_code"));
  let code = checkCookies("lan_code") ? lanCode.split("-")[0] : "en";
  var urlRedirect: string | undefined = "";
  if (textValue) {
    let finalCode = textValue.hasOwnProperty(code) ? code : "en";

    if (typeof textValue === "object") {
      for (let [key, value] of Object.entries(textValue)) {
        if (key === finalCode) {
          urlRedirect = value;
        }
      }
      if (urlRedirect == "") {
        urlRedirect = Object.values(textValue)[0]
          ? Object.values(textValue)[0]
          : "";
      }
      console.log("pruebaStringText1", urlRedirect, code);
    } else {
      urlRedirect = textValue;
    }
  }
  return urlRedirect;
};

export const getRedirectUrl = (urlObj: string | UrlLabelType | undefined) => {
  let lanCode = String(getCookie("lan_code"));
  let code = checkCookies("lan_code") ? lanCode.split("-")[0] : "en";
  var urlRedirect: string | undefined = "";
  var defaultUrlRedirect: string | undefined = "";
  let findUrl = false;
  if (urlObj) {
    if (typeof urlObj === "object") {
      if (Array.isArray(urlObj)) {
        let find = urlObj.find((f) => f.code == code);
        if (find) {
          findUrl = true;
          urlRedirect = find.url;
        } else {
          findUrl = true;
          find = urlObj.find((f) => f.code == "en");
          if (find) {
            urlRedirect = find.url;
          } else {
            if (urlObj.length == 1) {
              find = urlObj.find((f) => f);
              urlRedirect = find.url;
            } else {
              urlRedirect = "";
            }
          }
        }
      } else {
        for (let [key, value] of Object.entries(urlObj)) {
          if (key === code) {
            findUrl = true;
            urlRedirect = value;
          }
          if (key === "en") {
            defaultUrlRedirect = value;
          }
        }
      }
    } else {
      urlRedirect = urlObj;
    }
  }

  if (!findUrl) {
    urlRedirect = defaultUrlRedirect;
  }
  console.log("urlRedirec", urlObj, urlRedirect);

  return urlRedirect;
};

export const checkImagePath = (img: string) => {
  console.log("img->checkimg-init", img);
  if (img === "undefined") {
    console.log("img->checkimg-return", IMG_NOT_FOUND);
    return IMG_NOT_FOUND;
  }

  let firstLetters = img.substring(0, 3);
  if (firstLetters.includes("//")) {
    console.log("img->checkimg-//", `https:${img}`);
    return `https:${img}`;
  }

  if (firstLetters.includes("htt")) {
    console.log("img->checkimg-htt", String(img));
    return String(img);
  }

  console.log("img->checkimg-default", `${IMAGE_PATH}${img}`);
  return `${IMAGE_PATH}${img}`;
};

export const getTodayDate = () => {
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = String(today.getMonth() + 1); // Months start at 0!
  let dd = String(today.getDate());
  if (Number(dd) < 10) dd = "0" + dd;
  if (Number(mm) < 10) mm = "0" + mm;
  const formattedToday = `${yyyy}-${mm}-${dd}`;
  return formattedToday;
};

export const home = [
  "/",
  "/holidaystays",
  "cruises",
  "/cars",
  "/flight",
  "/transfers",
  "/activities",
  "/weeks",
  "/resorts",
  "/stays",
];

export const getDateStringFormat = (date: Date) => {
  const day = moment(date, "YYYY-MM-DD").format("YYYY-MM-DD");
  return String(day);
};

export const getDateByProduct = (product: string, query: any, key: string) => {
  let value = "";
  switch (product) {
    case "hotels":
      if (query?.dateRange) {
        const range = getDatesFromRange(String(query?.dateRange));
        if (key == "checkin") {
          value = range.date1;
        } else {
          value = range.date2;
        }
      }
      break;
    case "flight":
      if (key == "checkin" && query.from) {
        const checkIn: Date = new Date(String(query.from || ""));
        if (moment(checkIn).format("YYYY-MM-DD") !== "Invalid date") {
          value = moment(checkIn).format("YYYY-MM-DD");
        }
      } else if (key == "checkout" && query.to) {
        const checkOut: Date = new Date(String(query.to || ""));
        if (moment(checkOut).format("YYYY-MM-DD") !== "Invalid date") {
          value = moment(checkOut).format("YYYY-MM-DD");
        }
      }
      break;
    case "cars":
      if (key == "checkin" && query.pickupdate) {
        value = query.pickupdate;
      } else if (key == "checkout" && query.dropupdate) {
        value = query.dropupdate;
      }
      break;
    case "transfers":
      if (key == "checkin" && query.checkIn) {
        value = query.checkIn;
      } else if (key == "checkout" && query.checkOut) {
        if (query.checkIn == query.checkOut) {
          const checkOut: Date = new Date(String(query.checkOut || ""));
          if (moment(checkOut).format("YYYY-MM-DD") !== "Invalid date") {
            value = moment(addDays(checkOut, 2)).format("YYYY-MM-DD");
          }
        } else {
          value = query.checkOut;
        }
      }
      break;
    case "activities":
      if (key == "checkin" && query.checkin) {
        value = query.checkin;
      } else if (key == "checkout" && query.checkout) {
        value = query.checkout;
      }
      break;
    case "resorts":
      if (key == "checkin" && query.checkin) {
        value = query.checkin;
      } else if (key == "checkout" && query.checkout) {
        value = query.checkout;
      }
      break;
    case "stays":
      if (key == "checkin" && query.checkin) {
        value = query.checkin;
      } else if (key == "checkout" && query.checkout) {
        value = query.checkout;
      }
      break;

    default:
      if (key == "checkin" && query.checkin) {
        value = query.checkin;
      } else if (key == "checkout" && query.checkout) {
        value = query.checkout;
      }
      break;
  }

  // if (key == "checkin" && value == "") {
  //   value = moment(new Date()).format("YYYY-MM-DD");
  // } else if (key == "checkout" && value == "") {
  //   value = moment(addDays(new Date(), 1)).format("YYYY-MM-DD");
  // }

  return value;
};

export const isSameDay = (d1: string, d2: string) => {
  const d1HasT = d1.includes("T");
  const d2HasT = d2.includes("T");
  let date1 = d1;
  let date2 = d2;

  if (d1HasT) {
    date1 = d1.split("T")[0];
  }
  if (d2HasT) {
    date2 = d2.split("T")[0];
  }

  return new Date(date1).getTime() === new Date(date2).getTime();
};

export const filterRepeatItems = (data: MainCardType[], type: string) => {
  return data.reduce((acc: MainCardType[], current: MainCardType) => {
    const x = acc.find((item: MainCardType) => {
      if (type === "hotel") {
        return (
          item.title === current.title &&
          item.subtitle === current.subtitle &&
          isSameDay(String(item?.creation_time), String(current.creation_time))
        );
      }

      if (type === "car") {
        return (
          item.title === current.title &&
          isSameDay(String(item?.creation_time), String(current.creation_time))
        );
      }
      if (type === "flight") {
        return (
          item.title === current.title &&
          item.dateDeparture === current.dateDeparture &&
          item.subtitle === current.subtitle &&
          item.dateReturn === current.dateReturn &&
          isSameDay(String(item?.creation_time), String(current.creation_time))
        );
      }
    });
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);
};

export const orderObjectByDate = (data: MainCardType[], orderBy = "asc") => {
  data.sort(function (a, b) {
    if (a?.creation_time && b?.creation_time) {
      var keyA = new Date(a?.creation_time),
        keyB = new Date(b?.creation_time);
      // Compare the 2 dates
      if (orderBy == "asc") {
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
      } else {
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
      }
    }

    return 0;
  });
};

export const getScoreLabel = (label: string | ScoreLabelType | undefined) => {
  let lanCode = String(getCookie("lan_code"));
  let code = checkCookies("lan_code") ? lanCode.split("-")[0] : "en";

  if (typeof label === "object") {
    var scoreLabel = "";
    for (let [key, value] of Object.entries(label)) {
      if (key === code) {
        scoreLabel = value;
      }
    }
    return scoreLabel;
  } else {
    return label;
  }
};

export const checkImageUrl = (url: string) => {
  try {
    if (url.includes("https://") && url.includes("http://")) {
      const urlTemp = url.split("https://");
      const urlTemp2 = url.split("http://");

      const urlPath = urlTemp[1] as string;
      const urlPath2 = urlTemp2[1] as string;
      return urlPath || urlPath2;
    } else if (url.includes(RoomDefaultNotFound)) {
      return url;
    } else if (!url.includes("https://") && !url.includes("http://")) {
      let firstLetters = url.substring(0, 3);
      if (firstLetters.includes("//")) {
        return `https:${url}`;
      }

      if (firstLetters.includes("htt")) {
        return String(url);
      }
      return `${IMAGE_PATH}${url}`;
    }
    return url || "";
  } catch (error) {
    return RoomDefaultNotFound;
  }
};

export const setAdsCoordinates = async (url: string) => {
  let nurl = new URLSearchParams(url);
  removeCookies("searchLatitude");
  removeCookies("searchLongitude");
  let query: CardBodyFlighQuery = Array.from(nurl.keys()).reduce(
    (sum, value) => {
      return Object.assign({ [value]: nurl.get(value) }, sum);
    },
    {}
  );

  if (query && query?.latitude) {
    setCookies("searchLatitude", query?.latitude);
    setCookies("searchLongitude", query?.longitude);
  }

  return query;
};

export const getDateTime = (date: string, element: "h" | "d") => {
  const dateToFormat = moment(date);
  return element == "d"
    ? dateToFormat.local().format("ll")
    : dateToFormat.local().format("LT");
};

export const getDiffDays = (checkin: string, checkout: string) => {
  const checkIn = new Date(checkin);
  const checkOut = new Date(checkout);
  const diffTime = Math.abs(checkOut.getTime() - checkIn.getTime());
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

export const getDataAds = ({
  productType = "",
  spaceName = "",
  adsData = [],
}: {
  productType: string;
  spaceName: string;
  adsData: (MainCardType & { used?: boolean })[];
}) => {
  try {
    let pathName = window.location.pathname;
    pathName = pathName == "/home" ? pathName : pathName.replace("/home", "");
    const currentSection = getCurrentSection(pathName);
    const productConfig = adsConfig.find((f) => f.product == productType);
    const sectionConfig = productConfig?.sections?.find(
      (f) => f.name == currentSection
    );
    const spaceConfig = sectionConfig?.spaces.find((f) => f.name == spaceName);

    if (!spaceConfig) {
      return [];
    }
    const indexUsed = [] as number[];
    const cardsData = [] as MainCardType[];

    for (const config of spaceConfig.config || []) {
      const priority = config.priority;
      const typeStr = config.type.join("_");

      const findIndex = adsData.findIndex(
        (f) => f.petitionTypeStr == typeStr && f.priority == priority && !f.used
      );
      if (findIndex != -1) {
        const findIndexUsed = indexUsed.findIndex((f) => f == findIndex);
        if (findIndexUsed == -1) {
          indexUsed.push(findIndex);
          cardsData.push(adsData[findIndex]);
          adsData[findIndex] = { ...adsData[findIndex], used: true };
        }
      }
    }

    return cardsData;
  } catch (error) {
    console.log(error);
  }
  return [];
};
