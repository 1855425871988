import { INSPIRA_PG, STRIPE_ELEMENTS } from "@data/paymentData";
import { getImageNameV2 } from "@helpers/paymentMethods";
import {
  generatePgToken,
  getSupplierpg,
  getUserCreditCards,
} from "@lib/api/paymentMethods";
import { create } from "zustand";

interface PaymentMethodsStoreType {
  isLoading: boolean;
  newPgCardsUser: UserCreditCardOptionsType[];
  profileDefaultCard: string;
  creditCardsOptions: UserCreditCardOptionsType[];
  creditCards: UserCreditCardType[];
  suppliers: SupplierPgType[];
  selectedCreditCard: string;
  generatedToken: GeneratePgTokenResType | null;
  tokenParamsReq: GeneratePgTokenReqType | null;
  pgError: any;
  showOxxo: boolean;
  show3dSecure: boolean;
  childRef: any;
  showModalPaymentTokenFail: string;
  executeReloadPaymentMethod: boolean;
  forceResetAllInitPaymentMethod: boolean;
  subscriptionTrial: boolean;
  isEnabledPGV2: boolean;
  apiKeySupplier: string;
  setNewPgCardsUser: (val: UserCreditCardOptionsType[]) => void;
  setCreditCardsOptions: (val: UserCreditCardOptionsType[]) => void;
  setApiKeySupplier: (val: string) => void;
  fetchSupplierPG: (
    productType?: Wallet_Product_Type,
    pg_supplier_id?: string
  ) => void;
  fetchGetUserCreditCards: (email: string) => void;
  setSelectedCreditCard: (val: string) => void;
  fetchGenerateTokenPG: (params: GeneratePgTokenReqType) => void;
  setTokenParamsReq: (params: GeneratePgTokenReqType) => void;
  setPgError: (value: any) => void;
  setShowOxxo: (value: boolean) => void;
  setChildRef: (value: any) => void;
  setShow3dSecure: (value: boolean) => void;
  setExecuteReloadPaymentMethod: (value: boolean) => void;
  setForceResetAllInitPaymentMethod: (value: boolean) => void;
  walletCheckOutPaymentSummary: WalletPaymentSummaryType[];
  setWalletCheckOutPaymentSummary: (value: WalletPaymentSummaryType[]) => void;
  setSuppliers: (suppliers: SupplierPgType[]) => void;
  fetchGetUserCreditCardsPGV2: (cards: PgCardsSavedType[]) => void;
  setIsEnabledPGV2: (value: boolean) => void;
  clearData: () => void;
  pg_method_name: string;
  isClientCC: boolean;
  isInspiraCC: boolean;
  getSelectedCreditCard: () => string;
  getCreditCardsOptions: () => UserCreditCardOptionsType[];
}
export const PAY_WITH_NEW_CARD = "pay with new card";
export const NEW_CARD = "add new card";
export const INVOICE = "pay with invoice";
export const PAYMENT_TYPE_OXXO = "oxxo";

const initData = {
  profileDefaultCard: "",
  apiKeySupplier: "",
  isLoading: true,
  creditCards: [],
  suppliers: [],
  newPgCardsUser: [],
  creditCardsOptions: [],
  selectedCreditCard: "",
  generatedToken: null,
  tokenParamsReq: null,
  pgError: null,
  showOxxo: false,
  childRef: null,
  showModalPaymentTokenFail: "",
  show3dSecure: false,
  executeReloadPaymentMethod: false,
  forceResetAllInitPaymentMethod: false,
  subscriptionTrial: false,
  walletCheckOutPaymentSummary: [],
  isEnabledPGV2: false,
  pg_method_name: "",
  isClientCC: false,
  isInspiraCC: false,
};
export const usePaymentMethodsStore = create<PaymentMethodsStoreType>(
  (set, get) => ({
    ...initData,
    clearData: () => set({ ...initData }),
    setCreditCardsOptions: (value: UserCreditCardOptionsType[]) =>
      set({ creditCardsOptions: value }),
    setNewPgCardsUser: (value: UserCreditCardOptionsType[]) =>
      set({ newPgCardsUser: value }),
    setApiKeySupplier: (value: string) => {
      set({ apiKeySupplier: value });
    },
    setIsEnabledPGV2: (value: boolean) => {
      set({ isEnabledPGV2: value });
    },
    setSuppliers: (dataSuppliers: SupplierPgType[]) => {
      set({ suppliers: dataSuppliers });
    },
    setWalletCheckOutPaymentSummary: (value: WalletPaymentSummaryType[]) => {
      set({ walletCheckOutPaymentSummary: value });
    },
    fetchSupplierPG: async (productType, pg_supplier_id) => {
      set({ isLoading: true });

      const data: SupplierPgType[] = await getSupplierpg(
        productType,
        pg_supplier_id
      );
      if (Array.isArray(data)) {
        const enableInspira = data.some((s) => [INSPIRA_PG].includes(s.id));
        const apiKey = data?.find((item) => item)?.["api-key"];
        const pg_method_name = String(
          data?.find((x) => x.pg_method_name)?.pg_method_name || ""
        );
        set({
          suppliers: data,
          isEnabledPGV2: enableInspira,
          apiKeySupplier: apiKey,
          pg_method_name: pg_method_name || "",
          isClientCC: pg_method_name == "Client CC",
          isInspiraCC: pg_method_name == "Inspira CC",
        });
      }
      set({ isLoading: false });
    },
    fetchGetUserCreditCards: async (email: string) => {
      //!Descomentar cuando se active invoice
      // const { isEnabledPGV2 } = get();
      if (!email) {
        set({ isLoading: false });
        return;
      }
      set({ isLoading: true });
      const req = {
        merchant_id: "",
        email: email,
        mobile: "",
      };
      const data = await getUserCreditCards(req);
      if (Array.isArray(data)) {
        const init = [
          {
            value: NEW_CARD,
            label: "Add new card",
            title: "Add new card",
            cardImage: getImageNameV2("add"),
            uniquekey: "add",
          },
          {
            value: PAY_WITH_NEW_CARD,
            label: "Pay with new card",
            title: "Pay with new card",
            cardImage: getImageNameV2("new_card"),
            uniquekey: "other",
          },
        ] as UserCreditCardOptionsType[];
        //!Descomentar cuando se active invoice
        // if (isEnabledPGV2) {
        //   init.push({
        //     value: INVOICE,
        //     label: "Pay with invoice",
        //     title: "Pay with invoice",
        //     cardImage: getImageNameV2("invoice"),
        //     uniquekey: "invoice",
        //   });
        // }

        const options = data.map((m) => {
          if (m.is_default == 1) {
            set({ selectedCreditCard: m.merchant_id.toString() });
          }
          return {
            value: String(m.card_key),
            label: m.card_type,
            title: m.card_type,
            cardImage: getImageNameV2(m.card_type),
            uniquekey: "cards",
            cardData: m,
          };
        });

        let valueDefault = PAY_WITH_NEW_CARD;
        const existDefault = data.find((m) => m.is_default == 1);
        if (existDefault) {
          valueDefault = existDefault.card_key;
        }
        set({
          creditCardsOptions: init.concat(options),
          selectedCreditCard: valueDefault,
          pgError: "",
          showOxxo: false,
        });
      }
      set({ isLoading: false });
    },

    fetchGetUserCreditCardsPGV2: async (dataCards: PgCardsSavedType[]) => {
      //!agregar al get cuando se active invoice
      // const { isEnabledPGV2 } = get();
      const { selectedCreditCard } = get();
      if (dataCards.length == 0) {
        set({ isLoading: false });
        return;
      }
      set({ isLoading: true });

      if (Array.isArray(dataCards)) {
        const init = [
          {
            value: NEW_CARD,
            label: "Add new card",
            title: "Add new card",
            cardImage: getImageNameV2("add"),
            uniquekey: "add",
          },
          {
            value: PAY_WITH_NEW_CARD,
            label: "Pay with new card",
            title: "Pay with new card",
            cardImage: getImageNameV2("new_card"),
            uniquekey: "other",
          },
        ] as UserCreditCardOptionsType[];

        //!Descomentar cuando se active invoice
        // if (isEnabledPGV2) {
        //   init.push({
        //     value: INVOICE,
        //     label: "Pay with invoice",
        //     title: "Pay with invoice",
        //     cardImage: getImageNameV2("invoice"),
        //     uniquekey: "invoice",
        //   });
        // }

        const lastItem = dataCards.at(
          dataCards.length > 0 ? dataCards.length - 1 : 0
        );
        // if (lastItem) {
        //   set({ selectedCreditCard: lastItem.id });
        // }
        const findDefault = dataCards.find((m) => m.is_default);
        const options = dataCards.map((m) => {
          return {
            value: String(m.id),
            label: m.brand,
            title: m.brand,
            cardImage: getImageNameV2(m.brand),
            uniquekey: "cards",
            cardDataPGV2: m,
          };
        });

        set({ newPgCardsUser: options, profileDefaultCard: findDefault?.id });

        let valueDefault = PAY_WITH_NEW_CARD;

        console.log(
          "🚀 ~ fetchGetUserCreditCardsPGV2: ~ selectedCreditCard:",
          selectedCreditCard,
          lastItem
        );
        let isSelectedCard = true;
        //Agregar cuando se active invoice
        //!Descomentar cuando se active invoice
        // &&
        //   selectedCreditCard != INVOICE
        if (
          selectedCreditCard &&
          selectedCreditCard != PAY_WITH_NEW_CARD &&
          selectedCreditCard != NEW_CARD
        ) {
          isSelectedCard = options.some((m) => m.value == selectedCreditCard);
        }
        if (
          isSelectedCard &&
          Array.isArray(dataCards) &&
          dataCards.length > 0
        ) {
          set({
            creditCardsOptions: init.concat(options),
          });
        } else {
          let defaultCard = lastItem?.id ? lastItem.id : valueDefault;
          if (findDefault) {
            defaultCard = findDefault.id;
          }
          set({
            creditCardsOptions: init.concat(options),
            selectedCreditCard: defaultCard,
            pgError: "",
            showOxxo: false,
          });
        }
      }
      set({ isLoading: false });
    },
    setSelectedCreditCard: async (val: string) => {
      set({ selectedCreditCard: val });
    },

    fetchGenerateTokenPG: async (params: GeneratePgTokenReqType) => {
      set({ isLoading: true });
      const data = await generatePgToken(params);
      set({ generatedToken: data?.token ? data : null });
      if (data?.subscription_trial) {
        set({ subscriptionTrial: data?.subscription_trial });
      }
      set({ isLoading: false });

      if (!data?.subscription_trial) {
        set({
          showModalPaymentTokenFail: `${params?.card_id}_${
            data?.token ? `1` : `0`
          }`,
        });
      }
    },
    setTokenParamsReq: async (params: GeneratePgTokenReqType) => {
      set({ tokenParamsReq: params });
    },
    setPgError: async (value: string) => {
      set({ pgError: value });
    },
    setShowOxxo: async (value: boolean) => {
      set({ showOxxo: value });
    },
    setChildRef: async (value: any) => {
      set({ childRef: value });
    },
    setShow3dSecure: async (value: boolean) => {
      set({ show3dSecure: value });
    },
    setExecuteReloadPaymentMethod: async (value: boolean) => {
      set({ executeReloadPaymentMethod: value });
    },
    setForceResetAllInitPaymentMethod: async (value: boolean) => {
      set({ forceResetAllInitPaymentMethod: value });
    },
    getSelectedCreditCard: () => {
      const { selectedCreditCard } = get();
      return selectedCreditCard;
    },
    getCreditCardsOptions: () => {
      const { creditCardsOptions } = get();
      return creditCardsOptions;
    },
  })
);

// const getImageName = (name: string): string => {
//   if (name == "visa") {
//     return "visa.jpeg";
//   }
//   if (name == "visa") {
//     return "mastercard.png";
//   }
//   return "general.png";
// };

export const getCurrentOptionInfo = () => {
  const { creditCardsOptions, selectedCreditCard } =
    usePaymentMethodsStore.getState();
  const optionSelected = creditCardsOptions.find(
    (t) => t.value == selectedCreditCard
  );

  return optionSelected;
};

export const getCardPaymentReq = (totalAmount: number) => {
  const {
    creditCardsOptions,
    selectedCreditCard,
    showOxxo,
    generatedToken,
    suppliers,
  } = usePaymentMethodsStore.getState();
  const optionSelected = creditCardsOptions.find(
    (t) => t.value == selectedCreditCard
  );
  let data = null;
  const cardData = optionSelected?.cardData;
  const enableStripeElements = suppliers.some((s) => s.id == STRIPE_ELEMENTS);
  const enableInspiraElements = suppliers.some((s) => s.id == INSPIRA_PG);

  if (enableStripeElements) {
    let type = "stripe";
    if (totalAmount == 0) {
      type = "NA";
    } else if (showOxxo) {
      type = "oxxo";
    }
    if (cardData || selectedCreditCard == PAY_WITH_NEW_CARD) {
      data = {
        pg_token: generatedToken?.id,
        pg_type: type,
      };
    }
    // else if(showstripeorvpay){
    //   type = "vpay"
    // }
  } else if (enableInspiraElements) {
    data = {
      pg_type: INSPIRA_PG,
    };
  }

  return data;
};

export const open3DSecurePopup = (url3dSecure?: string) => {
  if (!url3dSecure) {
    return true;
  }
  // URL proporcionada por Stripe para la autenticación 3D Secure
  const stripe3DSecureURL = url3dSecure; // Reemplaza con tu URL real

  // Abre una nueva ventana o pestaña del navegador
  const newWindow = window.open(
    stripe3DSecureURL,
    "_blank",
    "width=600,height=400"
  );

  if (newWindow) {
    // Puedes realizar acciones adicionales aquí si es necesario, como cerrar la ventana después de un tiempo
    // newWindow.close();
  } else {
    // Si el navegador bloquea la ventana emergente, puedes mostrar un mensaje de error
    alert(
      "El navegador bloqueó la ventana emergente. Habilita las ventanas emergentes para continuar."
    );
  }
};

export const checkPaymentWithOxxo = async (childRef: any) => {
  const { showOxxo } = usePaymentMethodsStore.getState();

  if (childRef?.current && showOxxo) {
    const value = await childRef?.current?.handleSubmit();

    if (value?.paymentIntent?.status != "requires_action") {
      // * significa que no se a generado el ticket de oxxo
      return false;
    }
  }
  return true;
};

export const isAlphanumeric = (str: string) => {
  return /^[a-zA-Z0-9]+$/.test(str);
};
