import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { InputCustom } from "./InputCustom";
import {
  MainContainer,
  Title,
  MainPaymentContainer,
  PaymentContainer,
} from "./styles";
import { useWhiteLabelLan } from "@hooks/useLayout";
import { ApplyCouponCode } from "@lib/api/applyCouponCodeDetails";
import {
  checkEnabledCashCardWallet,
  useWalletStore,
} from "@store/walletUserStore";
import { shallow } from "zustand/shallow";
import { getCouponCodeDetails } from "@lib/api/getcouponcodedetails";
import { useWeeksCheckoutStore } from "@store/weeksCheckoutStore";
import { ORG_ID, SITE_ID } from "@helpers/config";
import { useMain } from "@contexts/mainContext";
import { unlockCouponCode } from "@lib/MainPaymentMethods/unlockCouponCode";
import { applyResortsCouponCode } from "@lib/MainPaymentMethods/ApplyResortCashCard";
import { CreditCards } from "./CreditCards";
import { usePaymentMethodsStore } from "@store/paymentMethodsStore";
import { INSPIRA_PG, STRIPE_ELEMENTS } from "@data/paymentData";
import dynamic from "next/dynamic";
import { Modal3DSecure } from "./Modal3DSecure";
import { fixedNumber } from "@lib/PersonalWallet/ImplementationLogic";
import { cashcardRedemption } from "@lib/api/cars/cashcardRedemption";
import { getCookie } from "cookies-next";
import axios from "axios";
import { useRouter } from "next/router";
import { getCurrencyByLanguage } from "@helpers/helperFormatCurrency";
import { useLayoutStore } from "@store/layoutStore";

const StripeElements = dynamic(() => import("./StripeElements"), {
  ssr: false,
});

type PaymentMethodsProps = {
  ids: string[] | string;
  productType: Wallet_Product_Type;
  totalPaymentAmount: number;
  childRef: any;
  extraParamsToken?: any;
  isDisabledCouponCode?: boolean;
  isDisabledCashCard?: boolean;
  onCompleteBooking: (complete?: boolean) => void;
  pg_supplier_id?: string;
  isOnlyCreditCards?: boolean;
  isDisabledCreditCard?: boolean;
};

export const PaymentMethods: React.FC<PaymentMethodsProps> = ({
  ids,
  productType,
  totalPaymentAmount,
  childRef,
  extraParamsToken,
  isDisabledCouponCode,
  isDisabledCashCard,
  onCompleteBooking,
  pg_supplier_id,
  isOnlyCreditCards = false,
  isDisabledCreditCard,
}) => {
  const { cashCardDetails } = useWalletStore(
    (state) => ({
      cashCardDetails: state.cashCardDetails,
    }),
    shallow
  );
  const { setCashCardApplied, setCouponCodeApplied } = useWeeksCheckoutStore();
  const { cashCardApplied, couponCodeApplied } = useWeeksCheckoutStore(
    (state) => ({
      // totalAmount: state.totalAmount,
      cashCardApplied: state.cashCardApplied,
      couponCodeApplied: state.couponCodeApplied,
    }),
    shallow
  );

  const { orgConfig } = useMain();
  const router = useRouter();
  const [couponData, setCouponData] = useState<CarCoupponCodeType[]>([]);
  const [coupon, setCoupon] = useState("");
  const [couponMessage, setCouponMessage] = useState("");
  const [successCouponMessage, setSuccessCouponMessage] = useState(false);
  const { getLangLabel } = useWhiteLabelLan();

  const [cashCard, setCashCard] = useState(0);
  const [cashCardPlaceHolder, setCashCardPlaceHolder] = useState(0);
  const [cashCardMessage, setCashCardMessage] = useState("");
  const [successCardMessage, setSuccessCardMessage] = useState(false);
  const { suppliers, generatedToken, show3dSecure } = usePaymentMethodsStore(
    (state) => ({
      suppliers: state.suppliers,
      generatedToken: state.generatedToken,
      show3dSecure: state.show3dSecure,
    }),
    shallow
  );
  const { btnLabelApplyCode } = useLayoutStore(
    (state) => ({
      btnLabelApplyCode: state.externalConfig?.btnLabelApplyCode,
    }),
    shallow
  );

  const enableStripeElements = suppliers.some((s) =>
    [STRIPE_ELEMENTS, INSPIRA_PG].includes(s.id)
  );

  const handlerOnchangeCoupon = (val: string) => {
    setCoupon(val);
  };

  const getCopuonAmount = (couponAmount: number) => {
    const newTotalPayment: number = fixedNumber(
      Number(totalPaymentAmount || 0)
    );
    console.log(
      "getCopuonAmount",
      couponAmount,
      newTotalPayment,
      totalPaymentAmount
    );
    if (couponAmount > newTotalPayment) {
      return newTotalPayment;
    } else {
      return couponAmount;
    }
  };
  const handleCashCardClick = async () => {
    console.log("applied", cashCard);
    if (Number(cashCard) > 0) {
      const process = cashCardApplied > 0 ? "unapply" : "apply";

      // if (productType == "WLT" && enableStripeElements) {
      //   const res = await applyResortsCouponCode(ids, productType, process);
      //   if (res.message == "applied") {
      //     setCashCardApplied(cashCard);
      //     setCashCardMessage(getLangLabel("CASH APPLIED SUCCESSFULLY"));
      //     setTimeout(() => {
      //       setCashCardMessage("");
      //     }, 5000);
      //   } else if (res.message == "unapplied") {
      //     setCashCardApplied(0);
      //     setCashCard(0);
      //     setCashCardMessage(getLangLabel("CASH RELEASED SUCCESSFULLY"));
      //     setTimeout(() => {
      //       setCashCardMessage("");
      //     }, 5000);
      //   }
      // } else if (productType == "WLT" && !enableStripeElements) {
      //   if (process == "apply") {
      //     const response = await axios.post(
      //       "/api/mywallet/cashcardredemption",
      //       {
      //         cashcard_amount: cashCard,
      //         order_amount: totalPaymentAmount,
      //         currency: String(getCookie("currency_code") || "USD"),
      //       }
      //     );
      //     setCashCardApplied(
      //       response?.data?.status == "success" ? cashCard : 0
      //     );
      //     setCashCardMessage(
      //       response?.data?.status == "success"
      //         ? getLangLabel("CASH APPLIED SUCCESSFULLY")
      //         : getLangLabel("FAILED TO APPLY CASH CARD")
      //     );
      //     setTimeout(() => {
      //       setCashCardMessage("");
      //     }, 5000);
      //   } else if (process == "unapply") {
      //     setCashCardApplied(0);
      //     setCashCard(0);
      //     setCashCardMessage(getLangLabel("CASH RELEASED SUCCESSFULLY"));
      //     setTimeout(() => {
      //       setCashCardMessage("");
      //     }, 5000);
      //   }
      // } else
      if (
        productType == "HLT" ||
        productType == "FLT" ||
        productType == "CAR"
      ) {
        let newID: string[] = [];
        if (typeof ids == "string") {
          if (productType == "HLT" && ids.includes(",")) {
            const stringToArray: string[] = ids.split(",");
            newID = stringToArray;
          } else {
            newID.push(ids);
          }
        } else {
          newID = ids;
        }
        if (process == "apply") {
          const query = router.query;
          let obj: CarCashCardRedemptionRequestType = {
            cash_card_id: "",
            booking_amt: String(totalPaymentAmount),
            cashcard_amount: String(cashCard),
            currency: String(getCookie("currency_code") || "USD"),
            id: productType == "CAR" ? String(query?.carId || "") : newID,
            product_type: productType,
          };
          const res = await cashcardRedemption(obj);
          setCashCardApplied(res?.status == "success" ? cashCard : 0);
          setCashCardMessage(
            res?.status == "success"
              ? getLangLabel("CASH APPLIED SUCCESSFULLY")
              : getLangLabel("FAILED TO APPLY CASH CARD")
          );
          setSuccessCardMessage(res?.status == "success" ? true : false);
          setTimeout(() => {
            setCashCardMessage("");
            setSuccessCardMessage(false);
          }, 5000);
        } else {
          const query = router.query;
          let json = {
            id: productType == "CAR" ? String(query?.carId || "") : newID,
            product_type: productType,
            type: "cashcard",
          };
          const response = await axios.post("/api/booking/removeCashcard", {
            json,
          });
          const message: string = String(
            response?.data?.result?.msg || response?.data?.result?.mgs || ""
          ).toLowerCase();
          if (message.indexOf("successfully") > -1) {
            setCashCardApplied(0);
            setCashCard(0);
            setCashCardMessage(getLangLabel("CASH RELEASED SUCCESSFULLY"));
            setSuccessCardMessage(true);
            setTimeout(() => {
              setSuccessCardMessage(false);
              setCashCardMessage("");
            }, 5000);
          }
        }
      } else {
        const res = await applyResortsCouponCode(ids, productType, process);
        console.log(res);
        if (res.message == "applied") {
          setCashCardApplied(cashCard);
          setCashCardMessage(getLangLabel("CASH APPLIED SUCCESSFULLY"));
          setSuccessCardMessage(true);
          setTimeout(() => {
            setCashCardMessage("");
            setSuccessCardMessage(false);
          }, 5000);
        } else if (res.message == "unapplied") {
          setCashCardApplied(0);
          setCashCard(0);
          setCashCardMessage(getLangLabel("CASH RELEASED SUCCESSFULLY"));
          setSuccessCardMessage(true);
          setTimeout(() => {
            setCashCardMessage("");
            setSuccessCardMessage(false);
          }, 5000);
        }
      }
    } else {
      setCashCardMessage(getLangLabel("Please Enter Cash Card Amount"));
      setSuccessCardMessage(false);
    }
  };
  const handlerOnClickCoupon = async () => {
    console.log("mainCoupon", coupon);
    if (coupon) {
      let tmp: CoupponCodeResponseType | undefined = undefined;
      const findCoupon: CarCoupponCodeType | undefined = couponData?.find(
        (obj) => coupon === obj.coupon_code
      );
      console.log("findCoupon", findCoupon);
      if (findCoupon) {
        tmp = { ...findCoupon };
      }
      console.log("findCoupontmp", { tmp: tmp });
      if (tmp?.coupon_code) {
        console.log("findCoupon tmp?.coupon_code", tmp?.coupon_code);
        const infoCoupon = JSON.parse(
          String(localStorage.getItem("couponCodeInfo") || "{}")
        );
        console.log(`infoCoupon: `, { infoCoupon: infoCoupon });
        let obj = {
          coupon_code: tmp?.coupon_code,
          discount_amount: getCopuonAmount(totalPaymentAmount || 0),
          discount_currency: String(tmp?.coupon_currency || ""),
          product_type: productType,
          org_id: Number(ORG_ID || orgConfig?.orgId),
          coupon_id: Number(infoCoupon?.coupon_id || 0),
          coupon_trn_id: String(infoCoupon?.coupon_trn_id || ""),
          isMathch: tmp?.coupon_code == String(infoCoupon?.coupon_code || ""),
          site_id: SITE_ID,
        };
        console.log("findCoupon obj", obj, couponCodeApplied);
        if (!couponCodeApplied) {
          const res = await ApplyCouponCode(obj);

          if (res?.status) {
            const resCustom: WeeksCoupponCodeType = {
              coupon_trn_id: res.coupon_trn_id,
              applied_amount: res.applied_amount,
              applied_type: res.applied_type,
              coupon_id: res.coupon_id,
              coupon_code: res.coupon_code,
              discount_currency: res.discount_currency,
            };
            localStorage.setItem(
              "couponCodeInfo",
              JSON.stringify(resCustom || "")
            );

            console.log("res--->applyCoupon", res, resCustom, obj);

            setCouponCodeApplied(resCustom);
            setCouponMessage(getLangLabel(String(res.message || "")));
            setSuccessCouponMessage(true);
          } else {
            setCouponMessage(
              getLangLabel(String(res?.message || "").toUpperCase())
            );
            setSuccessCouponMessage(false);
          }
        } else {
          const req: UnlockCouponCodeType = {
            coupon_id: couponCodeApplied?.coupon_id,
            coupon_trn_id: couponCodeApplied?.coupon_trn_id,
            org_id: Number(ORG_ID || orgConfig?.orgId),
            product_type: productType,
            site_id: SITE_ID,
          };
          const res = await unlockCouponCode(req);

          if (res.status) {
            setCouponCodeApplied(null);
            setCouponMessage(res.message);
            localStorage.setItem("couponCodeInfo", "");
            setSuccessCouponMessage(true);
          }
          console.log("removeCoupon", res, req);
        }
      } else {
        setCouponMessage(getLangLabel("PLESE ENTER A VALID CUPON CODE"));
        setSuccessCouponMessage(false);
      }
    } else {
      setCouponMessage(
        getLangLabel("You Do Not Have Any Coupon Code To Apply")
      );
      setSuccessCouponMessage(false);
    }
    setTimeout(() => {
      setCouponMessage("");
      setSuccessCouponMessage(false);
    }, 5000);
  };

  const handlerOnchangeCashCard = (val: string) => {
    var pat = /^\d*(\.\d{0,2})?$/;
    if (pat.test(val)) {
      const available = Number(cashCardDetails?.total_amount);
      const TotalPayment: number = fixedNumber(Number(totalPaymentAmount || 0));
      const typeTotalAmount: number = Number(val || 0);
      if (available == typeTotalAmount || typeTotalAmount == 0) {
        setCashCardPlaceHolder(Number(available));
      } else {
        setCashCardPlaceHolder(Number(0));
      }
      if (available > TotalPayment) {
        if (typeTotalAmount < TotalPayment) {
          setCashCard(Number(typeTotalAmount));
        } else {
          setCashCard(Number(TotalPayment));
        }
      } else {
        if (typeTotalAmount < available) {
          setCashCard(Number(typeTotalAmount));
        } else {
          setCashCard(Number(available));
        }
      }
      /*   if (Number(val) <= available) {
        setCashCard(Number(val));
      } else {
        setCashCard(Number(available));
      } */
    }
  };

  // const isEnabledCertificate = checkEnabledCertificateWallet();
  const isEnabledCashCard = checkEnabledCashCardWallet();

  useEffect(() => {
    if (productType) {
      getCouponCodeDetails(productType).then((res) => {
        if (res && res?.length > 0) {
          setCouponData(res);
        }
      });
    }
    console.log("productType-1", productType);
    return () => {};
  }, [productType]);

  useEffect(() => {
    if (cashCardApplied) {
      setCashCard(cashCardApplied);
    }
    if (couponCodeApplied) {
      setCoupon(couponCodeApplied?.coupon_code);
    }
  }, [couponCodeApplied, cashCardApplied]);

  const isEnabledCoupun = true; //couponData.some((obj) => !!obj.coupon_code);
  const showPaymentMethodsComponent: boolean =
    isEnabledCoupun || isEnabledCashCard || enableStripeElements ? true : false;
  // if (!isEnabledCoupun && !isEnabledCashCard) {
  //   return <>sss</>;
  // }

  useEffect(() => {
    handlerOnchangeCashCard(`${cashCardDetails?.total_amount}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashCardDetails]);

  console.log(`PaymentMethods()_id: ${ids}, typeof: ${typeof ids}`, {
    enableStripeElements: enableStripeElements,
    suppliers: suppliers,
  });
  return (
    <>
      <MainContainer
        fluid
        className="payment-methods-main"
        theme={{ show: showPaymentMethodsComponent }}
      >
        <Row>
          <Title className={"payment-methods-main"}>
            {getLangLabel("PAYMENT METHODS")}
          </Title>
        </Row>
        <MainPaymentContainer>
          <PaymentContainer
            theme={{ show: isEnabledCoupun && !isDisabledCouponCode }}
          >
            <div className="show-coupon-code">
              <InputCustom
                title={getLangLabel("Have a Coupon Code?")}
                imageName={"CouponCode"}
                value={coupon}
                onChange={handlerOnchangeCoupon}
                textBtn={
                  couponCodeApplied
                    ? getLangLabel("Unapply")
                    : getLangLabel(
                        btnLabelApplyCode ? btnLabelApplyCode : "Apply"
                      )
                }
                placeholder={getLangLabel("Code Here")}
                onClick={handlerOnClickCoupon}
                message={couponMessage}
                disabled={couponCodeApplied ? true : false}
                successMessage={successCouponMessage}
              />
            </div>
          </PaymentContainer>
          <PaymentContainer
            theme={{ show: isEnabledCashCard && !isDisabledCashCard }}
          >
            <InputCustom
              title={getLangLabel("Use Your Cash Card(S)?")}
              imageName={"CashCard"}
              value={cashCardPlaceHolder > 0 ? "" : cashCard}
              onChange={handlerOnchangeCashCard}
              textBtn={
                cashCardApplied > 0
                  ? getLangLabel("Unapply")
                  : getLangLabel(
                      btnLabelApplyCode ? btnLabelApplyCode : "Apply"
                    )
              }
              placeholder={
                cashCardPlaceHolder > 0
                  ? `${getCurrencyByLanguage(
                      Number(cashCardPlaceHolder || 0)
                    )} `
                  : ""
              }
              subtitle={`${getLangLabel("Total Available:")} ${
                cashCardDetails?.currency
              } ${getCurrencyByLanguage(
                Number(cashCardDetails?.total_amount || 0),
                { simbol: false }
              )}`}
              currency={`${cashCardDetails?.currency}}`}
              inputType={"text"}
              onClick={handleCashCardClick}
              successMessage={successCardMessage}
              message={cashCardMessage}
              disabled={cashCardApplied ? true : false}
            />
          </PaymentContainer>
          <PaymentContainer theme={{ show: !isDisabledCreditCard }}>
            <CreditCards
              ids={ids}
              productType={productType}
              totalAmount={totalPaymentAmount}
              extraParamsToken={extraParamsToken}
              show={enableStripeElements}
              isOnlyCreditCards={isOnlyCreditCards}
              pg_supplier_id={pg_supplier_id}
            />
          </PaymentContainer>
        </MainPaymentContainer>
      </MainContainer>
      {enableStripeElements && (
        <Row>
          <Col xs={12}>
            <StripeElements
              childRef={childRef}
              productType={productType}
              pg_supplier_id={pg_supplier_id}
            />
          </Col>
        </Row>
      )}
      {show3dSecure && generatedToken?.auth_url && (
        <Modal3DSecure
          url3DSecure={generatedToken?.auth_url}
          show={show3dSecure}
          onCompleteBooking={onCompleteBooking}
        />
      )}
    </>
  );
};
