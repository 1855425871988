import axios from "axios";

export const PREFIX = "inspiraPG";
export const INIT_SAVED_CARD = PREFIX + "initSaveCard";
export const INIT_SAVED_CARD_URL = PREFIX + "/payment/initSaveCard";

export const INIT_BOOK = PREFIX + "initbookProcess";
export const INIT_BOOK_URL = PREFIX + "/initbook";

export const HANDLE_SAVED_CARD = PREFIX + "handleSaveCard";
export const HANDLE_SAVED_CARD_URL = PREFIX + "/payment/handleSaveCard";

export const INIT_PAY_NEW_CARD = PREFIX + "initPayNewCard";
export const INIT_PAY_NEW_CARD_URL = PREFIX + "/payment/initPayNewCard";

export const UPDATE_PAY_NEW_CARD = PREFIX + "updatePayment";
export const UPDATE_PAY_NEW_CARD_URL = PREFIX + "/payment/updatePayment";

export const HANDLE_PAY_CLICK_NEW_CARD = PREFIX + "handlePayClick";
export const HANDLE_PAY_CLICK_NEW_CARD_URL = PREFIX + "/payment/handlePayClick";

export const INIT_CERO_PAY_LATER = PREFIX + "initPayNewCardCeroPayLater";
export const INIT_CERO_PAY_LATER_URL =
  PREFIX + "/payment/initPayNewCardCeroPayLater";

export const UPDATE_CERO_PAY_LATER = PREFIX + "updatePayNewCardCeroPayLater";
export const UPDATE_CERO_PAY_LATER_URL =
  PREFIX + "/payment/updatePayNewCardCeroPayLater";

export const INIT_CREDIT_CARD = PREFIX + "initCreditCard";
export const INIT_CREDIT_CARD_URL = PREFIX + "/payment/initCreditCard";

export const HANDLE_CREDIT_CARD = PREFIX + "inithandleCCSubmit";
export const HANDLE_CREDIT_CARD_URL = PREFIX + "/payment/inithandleCCSubmit";

export const HANDLE_PAY_WITH_SAVED_CARD = PREFIX + "paymentWithSavedCard";
export const HANDLE_PAY_WITH_SAVED_CARD_URL =
  PREFIX + "/payment/paymentWithSavedCard";

export const HANDLE_PAY_WITH_INVOICE = PREFIX + "paymentWithInvoice";
export const HANDLE_PAY_WITH_INVOICE_URL =
  PREFIX + "/payment/paymentWithInvoice";

interface Data {
  dataRequest?: object;
  dataResponse?: object;
  dataErrorResponse?: object;
  processName: string;
  url: string;
}
export const loggerServer = async (
  data: Data
): Promise<CarCheckoutResponseDataType | null> => {
  try {
    const response = await axios.post(`/api/logger`, { ...data });
    return response?.data;
  } catch (error) {
    console.info(error);
    return null;
  }
};

type LoggerDataRequest = {
  platform: string;
  server_ip: string;
  site_id: string;
  task: string;
  session: string;
  content: {
    uuid: {
      correlationId: string;
      uuidTransaction: string;
      uuidSSO: string;
      uuid: string;
    };
    data: string;
  };
};
const setLoggerRegister = async (data: LoggerDataRequest): Promise<any> => {
  try {
    const response = await axios.post(`/api/LoggerApi/LoggerApiCall`, {
      ...data,
    });
    return response;
  } catch (error) {
    console.info("CATCH: /api/LoggerApi/LoggerApiCall", error);
    return [];
  }
};

export default setLoggerRegister;
