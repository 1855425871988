import { Col, Row } from "react-bootstrap";
import {
  ColDiv,
  MainContainer,
  RowDiv,
  Span,
  Title,
  TermsOfBooking,
  TermsAndConditionsLink,
  TermsAndConditionsNoLink,
  TermsBookingSpan,
  ContainerButton,
  RowDivButton,
} from "./styles";
import { usePgSwitchSDK } from "@hooks/usePgSwitchSDK";
import { useEffect, useState } from "react";
import { paymentMethodsPgV2Store } from "@store/paymentMethodsPgV2Store";
import { shallow } from "zustand/shallow";
import {
  NEW_CARD,
  PAY_WITH_NEW_CARD,
  usePaymentMethodsStore,
} from "@store/paymentMethodsStore";
import {
  getCountryCode,
  getCurrencyCode,
  getHost,
  getLangCodeFull,
  getSymb,
} from "@helpers/helper";
import { generateCheckSum } from "@lib/api/pgv2/generateCheckSum";
import { useRouter } from "next/router";
import { useWhiteLabelLan } from "@hooks/useLayout";
import { Btn } from "@components/General/Button/style";
import { useAuth } from "@contexts/AuthContext";
import { INSPIRA_PG, ELEMETS_ADD_NEW_CARD } from "@data/paymentData";
import { getCookie, setCookies } from "cookies-next";
import { LoaderSpin } from "@components/LoaderSpin";
import { ModalStripeElementsProccessCardFail } from "@components/Book/ModalStripeElementsProccessCardFail";
import { useBreakpoints } from "@hooks/useBreakpoints";
import {
  HANDLE_CREDIT_CARD,
  HANDLE_CREDIT_CARD_URL,
  HANDLE_PAY_CLICK_NEW_CARD,
  HANDLE_PAY_CLICK_NEW_CARD_URL,
  HANDLE_SAVED_CARD,
  HANDLE_SAVED_CARD_URL,
  INIT_CERO_PAY_LATER,
  INIT_CREDIT_CARD,
  INIT_CREDIT_CARD_URL,
  INIT_PAY_NEW_CARD,
  INIT_PAY_NEW_CARD_URL,
  INIT_SAVED_CARD,
  INIT_SAVED_CARD_URL,
  loggerServer,
  UPDATE_CERO_PAY_LATER,
  UPDATE_CERO_PAY_LATER_URL,
  UPDATE_PAY_NEW_CARD,
  UPDATE_PAY_NEW_CARD_URL,
} from "@lib/api/logger/setLoggerRegister";
import { getOrgId } from "@helpers/bookingOtherOrg";
import { useLayoutStore } from "@store/layoutStore";

type Props = {
  productType: Wallet_Product_Type;
  pathSuccess: string;
  forceReload?: boolean;
  initBook: (_e: any, completeBookin?: boolean) => Promise<string | null>;
  iframe?: string;
  price: number;
  is_renewal?: boolean;
  charge_org?: boolean;
  is_trial?: boolean;
  buttonLabel?: string;
  debt_payed?: boolean;
  disabledButton?: boolean;
  children?: any;
  nameFormPg?: string;

  handleShowCancellationPolicy?: () => void;
  handleSetShowTermsAndConditions?: () => void;
  handleSetShowCheckInInstructios?: () => void;
  handleSetShowRules?: () => void;
  setModalDetailsShow?: () => void;
  preventGoToCheckoutSuccess?: boolean;
  executeProccedBooking?: (booking_id: number) => Promise<void>;
  extraProceed?: (value: boolean) => void;
  descripcionPayment?: string;
  currency?: string;
};

const InspiraPg: React.FC<Props> = ({
  productType,
  pathSuccess,
  forceReload = false,
  initBook,
  iframe = "",
  price,
  is_renewal,
  charge_org,
  is_trial,
  debt_payed,
  disabledButton = false,
  buttonLabel = "COMPLETE BOOKING",
  children,
  nameFormPg = "payment",
  handleShowCancellationPolicy,
  handleSetShowTermsAndConditions,
  handleSetShowCheckInInstructios,
  handleSetShowRules,
  setModalDetailsShow,
  preventGoToCheckoutSuccess = false,
  executeProccedBooking,
  descripcionPayment,
  extraProceed,
  currency,
}) => {
  const { staysPayLater } = useLayoutStore(
    (state) => ({
      staysPayLater: state.externalConfig?.staysPayLater,
    }),
    shallow
  );
  const [loadButtonCC, setLoadButtonCC] = useState<boolean>(false);
  const router = useRouter();
  const { getLangLabel } = useWhiteLabelLan();
  const [savedCard, setSavedCard] = useState<boolean>(false);
  const { currentUser } = useAuth();
  const [isSavedCard, setIsSavedCard] = useState(true);
  const [buyButtonSpin, setBuyButtonSpin] = useState(false);
  const [savedCardSpin, setSavedCardSpin] = useState(false);
  const [creditCardSpin, setCreditCardSpin] = useState(false);
  const [enableButtonPay, setEnableButtonPay] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { pgSwitchSDK } = usePgSwitchSDK();
  const [showStripeElementsCardFail, setShowStripeElementsCardFail] =
    useState(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const handleSetShowStripeElementsCardFail = (state: boolean) => {
    setShowStripeElementsCardFail(state);
  };
  const { breakpoints } = useBreakpoints();
  const {
    fetchLoadCards,
    setLoadSaveNewCardForm,
    setHideBtnBook,
    setInitPaymentData,
    setHideCardRequired,
    setCc_required,
  } = paymentMethodsPgV2Store();
  const {
    paymentInfo,
    initPaymentData,
    isCustomerLoaded,
    loadSaveNewCardForm,
    hideCardRequired,
    cc_required,
    extraMetadataHLTPriceHange,
  } = paymentMethodsPgV2Store(
    (state) => ({
      paymentInfo: state.paymentInfo,
      initPaymentData: state.initPaymentData,
      isCustomerLoaded: state.isCustomerLoaded,
      loadSaveNewCardForm: state.loadSaveNewCardForm,
      hideCardRequired: state.hideCardRequired,
      cc_required: state.cc_required,
      extraMetadataHLTPriceHange: state.extraMetadataHLTPriceHange,
    }),
    shallow
  );

  const extraDataUrl =
    is_renewal !== undefined &&
    charge_org !== undefined &&
    is_trial !== undefined
      ? `&is_renewal=${is_renewal}&charge_org=${charge_org}&is_t=${is_trial}`
      : "";

  const pgCurrency = currency || getCurrencyCode();
  /* Cambios de prueba Pablo */

  async function creditCard() {
    setLoadButtonCC(false);
    let paymentElement = document.getElementById("credit-card-element");
    let buttonElement = document.getElementById("credit-card-submit");
    setHideBtnBook(true);
    await loggerServer({
      dataRequest: { initData: "just init credit card" },
      processName: INIT_CREDIT_CARD,
      url: INIT_CREDIT_CARD_URL,
    });
    try {
      const res = await pgSwitchSDK.initCreditCard(
        paymentElement,
        buttonElement
      );
      await loggerServer({
        dataResponse: res,
        processName: INIT_CREDIT_CARD,
        url: INIT_CREDIT_CARD_URL,
      });
      setLoadButtonCC(true);
    } catch (e: any) {
      await loggerServer({
        dataErrorResponse: e,
        processName: INIT_CREDIT_CARD,
        url: INIT_CREDIT_CARD_URL,
      });
      setLoadButtonCC(false);
      console.log(e);
    }
  }

  /*Fin Cambios de prueba Pablo */

  const { setSelectedCreditCard } = usePaymentMethodsStore();
  const { selectedCreditCard, apiKeySupplier, creditCardsOptions, isClientCC } =
    usePaymentMethodsStore(
      (state) => ({
        selectedCreditCard: state.selectedCreditCard,
        apiKeySupplier: state.apiKeySupplier,
        creditCardsOptions: state.creditCardsOptions,
        isClientCC: state.isClientCC,
      }),
      shallow
    );
  const isDisabledForMemberShip: boolean =
    productType == "SUB"
      ? selectedCreditCard == ELEMETS_ADD_NEW_CARD?.name
      : false;
  async function initSaveCard() {
    if (!pgSwitchSDK) {
      console.log("SDK not initialized");
      return;
    }
    if (!paymentInfo) {
      console.log("paymentInfo not initialized");
      return;
    }
    try {
      const element = document.getElementById("save-card-element") as any;
      element.innerHTML = "";
      console.log("Initializing SDK...");

      const paymentElement = document.getElementById("save-card-element");
      const buttonElement = document.getElementById("save-card-submit");
      const initData = {
        description: "save new card",
        authentication_type: "authentication_type",
        ip: "127.0.0.1",
        ...paymentInfo,
        metadata: {
          language_code: getLangCodeFull(),
        },
      };
      await loggerServer({
        dataRequest: initData,
        processName: INIT_SAVED_CARD,
        url: INIT_SAVED_CARD_URL,
      });

      const init = await pgSwitchSDK.initSaveCard(
        paymentElement,
        buttonElement,
        { ...initData }
      );
      buttonElement?.removeEventListener("click", saveCard);
      buttonElement?.addEventListener("click", saveCard);
      console.log("🚀 ~ initSaveCard ~ init:", init);
    } catch (e) {
      console.log("Error during initialization: ", e);
    }
  }

  async function initPaymentNewCard(
    extraMetadataHLTPriceHange: RequestMultiBookExtraMetadataType | null,
    priceFinal: number
  ) {
    const bookingId = initPaymentData?.order_id;
    if (!pgSwitchSDK) {
      console.info("initPaymentNewCard SDK not initialized");
      return;
    }
    if (!initPaymentData) {
      console.info("initPaymentNewCard paymentInfo not initialized");
      return;
    }
    try {
      const element = document.getElementById(`${nameFormPg}-element`) as any;
      element.innerHTML = "";
      console.info("Initializing SDK...");
      const checkSumRes = await generateCheckSum({
        orderId: "",
        currency: pgCurrency,
        countryCode: getCountryCode(),
        amount: priceFinal,
        api_key: apiKeySupplier,
      });
      const host = getHost();
      const paymentElement = document.getElementById(`${nameFormPg}-element`);
      const buttonElement = document.getElementById(`${nameFormPg}-submit`);
      console.info({ extraMetadataHLTPriceHange: extraMetadataHLTPriceHange });

      const initData = {
        ip: "127.0.0.1",
        currency: pgCurrency || initPaymentData.currency,
        primary_residence: initPaymentData.country_code,
        country_code: initPaymentData.country_code,
        amount: priceFinal,
        email: initPaymentData.email,
        return_url: `${host}${pathSuccess}`,
        checksum: checkSumRes || "",
        description: descripcionPayment
          ? descripcionPayment
          : `${productType} book: ${bookingId}`,
        metadata: {
          productType,
          api_key: currentUser?.userToken,
          currency: pgCurrency,
          language_code: getLangCodeFull(),
          origin: host,
          pgType: INSPIRA_PG,
          correlation_id: getCookie("correlationId"),
          org_id: getOrgId()?.org_id,
          service: getOrgId()?.book_service,
          user_id: currentUser?.userId,
          ...(productType === "HTL"
            ? {
                isPriceChange: extraMetadataHLTPriceHange?.isPriceChange
                  ? true
                  : false,
                room_id: extraMetadataHLTPriceHange?.room_id
                  ? extraMetadataHLTPriceHange?.room_id
                  : undefined,
                booking_ref_no: extraMetadataHLTPriceHange?.booking_ref_no
                  ? extraMetadataHLTPriceHange?.booking_ref_no
                  : undefined,
                isSelectDiffRoom: extraMetadataHLTPriceHange?.isSelectDiffRoom
                  ? true
                  : false,
              }
            : {}),
          ...(productType === "CAR"
            ? {
                prebook: true,
                cc_required,
              }
            : {}),
          ...(productType === "SUB"
            ? {
                charge_org,
                is_renewal,
                is_trial,
                ...(debt_payed ? { debt_payed } : {}),
              }
            : {}),
          ...(productType === "STY" && staysPayLater === true
            ? {
                staysPayLater,
              }
            : {}),
        },
      };
      await loggerServer({
        dataRequest: initData,
        processName: INIT_PAY_NEW_CARD,
        url: INIT_PAY_NEW_CARD_URL,
      });
      const init = await pgSwitchSDK.init(
        paymentElement,
        buttonElement,
        initData
      );

      // buttonElement?.removeEventListener("click", handlePayClick);
      // buttonElement?.addEventListener("click", handlePayClick);
      if (init.payment_id) {
        await loggerServer({
          dataResponse: init,
          processName: INIT_PAY_NEW_CARD,
          url: INIT_PAY_NEW_CARD_URL,
        });
        setEnableButtonPay(false);
        setCookies("paymentIdC", init.payment_id);
      } else {
        await loggerServer({
          dataErrorResponse: init,
          processName: INIT_PAY_NEW_CARD,
          url: INIT_PAY_NEW_CARD_URL,
        });
      }
      console.info("🚀 ~ initPaymentNewCard ~ init:", init);
    } catch (e) {
      console.info("Error during initPaymentNewCard initialization: ", e);
    }
  }
  async function saveCard(e: any) {
    setSavedCardSpin(true);
    const result: CardSavedPGType | null = await pgSwitchSDK.handleSaveCard(e);
    if (result?.success) {
      await loggerServer({
        dataResponse: result,
        processName: HANDLE_SAVED_CARD,
        url: HANDLE_SAVED_CARD_URL,
      });
      setLoadSaveNewCardForm(false);
      fetchLoadCards();
      if (productType !== "SUB") {
        setHideBtnBook(false);
      }
      setSavedCard(true);
      setSavedCardSpin(false);
    } else {
      await loggerServer({
        dataErrorResponse: { result },
        processName: HANDLE_SAVED_CARD,
        url: HANDLE_SAVED_CARD_URL,
      });
      setSavedCard(false);
      setSavedCardSpin(false);
    }
  }

  /** Modificacion pablo */
  async function passCreditCard(bookingId: string) {
    const checkSumRes = await generateCheckSum({
      orderId: "",
      currency: pgCurrency,
      countryCode: getCountryCode(),
      amount: 0,
      api_key: apiKeySupplier,
    });
    const host = getHost();
    const paymentElement = document.getElementById("credit-card-element");
    const buttonElement = document.getElementById("credit-card-submit");
    console.log({ extraMetadataHLTPriceHange: extraMetadataHLTPriceHange });
    const dataInit = {
      ip: "127.0.0.1",
      currency: pgCurrency,
      primary_residence: getCountryCode(),
      country_code: getCountryCode(),
      amount: 0,
      email: initPaymentData?.email,
      return_url: `${host}${pathSuccess}`,
      checksum: checkSumRes || "",
      description: descripcionPayment
        ? descripcionPayment
        : `${productType} book: ${bookingId}`,
      metadata: {
        userCreditCard: true,
        bookingId: bookingId,
        productType,
        api_key: currentUser?.userToken,
        currency: pgCurrency,
        language_code: getLangCodeFull(),
        origin: host,
        pgType: INSPIRA_PG,
        correlation_id: getCookie("correlationId"),
        org_id: getOrgId()?.org_id,
        service: getOrgId()?.book_service,
        user_id: currentUser?.userId,
        ...(productType === "HTL"
          ? {
              isPriceChange: extraMetadataHLTPriceHange?.isPriceChange
                ? true
                : false,
              room_id: extraMetadataHLTPriceHange?.room_id
                ? extraMetadataHLTPriceHange?.room_id
                : undefined,
              booking_ref_no: extraMetadataHLTPriceHange?.booking_ref_no
                ? extraMetadataHLTPriceHange?.booking_ref_no
                : undefined,
              isSelectDiffRoom: extraMetadataHLTPriceHange?.isSelectDiffRoom
                ? true
                : false,
            }
          : {}),
        ...(productType === "CAR" || (productType === "FLT" && isClientCC)
          ? {
              prebook: true,
              cc_required: true,
            }
          : {}),
        ...(productType === "SUB"
          ? {
              charge_org,
              is_renewal,
              is_trial,
              ...(debt_payed ? { debt_payed } : {}),
            }
          : {}),
      },
    };
    await loggerServer({
      dataRequest: dataInit,
      processName: INIT_CREDIT_CARD,
      url: INIT_CREDIT_CARD_URL,
    });

    const init = await pgSwitchSDK.init(
      paymentElement,
      buttonElement,
      dataInit
    );
    console.log("🚀 ~ passCreditCard >>>>> ~ init:", init);
    await loggerServer({
      dataResponse: init,
      processName: INIT_CREDIT_CARD,
      url: INIT_CREDIT_CARD_URL,
    });
    if (
      init &&
      //init.provider_credential.client_token === undefined &&
      init.payment_id &&
      init?.return_url
    ) {
      const dataCC = {
        bookingId: bookingId,
        productType,
        api_key: currentUser?.userToken || "",
        currency: pgCurrency,
        language_code: getLangCodeFull(),
        origin: getHost(),
        pgType: INSPIRA_PG,
        correlation_id: getCookie("correlationId"),
        ...(productType === "CAR" || (productType === "FLT" && isClientCC)
          ? { prebook: true, cc_required }
          : {}),
      };

      await loggerServer({
        dataRequest: { ...dataCC, payment_id: init.payment_id },
        processName: HANDLE_CREDIT_CARD,
        url: HANDLE_CREDIT_CARD_URL,
      });

      try {
        const data: CardCreditPGType | null = await pgSwitchSDK.handleCCSubmit(
          init.payment_id,
          dataCC
        );
        await loggerServer({
          dataResponse: { ...data },
          processName: HANDLE_CREDIT_CARD,
          url: HANDLE_CREDIT_CARD_URL,
        });
        console.log("🚀 ~ passCreditCard>>>> ~ data:", data);
        //setHideBtnBook(false);
        //setInitPaymentDataCC(null);
        //setHideCardRequired(false);
        setCreditCardSpin(false);
        if (data?.status && !preventGoToCheckoutSuccess) {
          window.location.href = `${pathSuccess}?booking_id=${bookingId}${extraDataUrl}`;
        }
        if (
          data?.status &&
          preventGoToCheckoutSuccess &&
          productType !== "SUB" &&
          executeProccedBooking
        ) {
          executeProccedBooking(Number(bookingId || 0));
        } else if (
          data?.status &&
          productType === "SUB" &&
          preventGoToCheckoutSuccess &&
          extraProceed
        ) {
          extraProceed(true);
        }
      } catch (e: any) {
        await loggerServer({
          dataErrorResponse: { ...e },
          processName: HANDLE_CREDIT_CARD,
          url: HANDLE_CREDIT_CARD_URL,
        });
        console.log(e);
      }
    }
  }

  /** Modificacion pablo */

  const updateData = async (_e: any, bookingIdResp: string) => {
    try {
      const paymentId = getCookie("paymentIdC");
      if (paymentId) {
        const metadataUpdate = {
          payment_id: paymentId,
          order_id: bookingIdResp,
          description: descripcionPayment
            ? descripcionPayment
            : `${productType} book: ${bookingIdResp}`,
          metadata: {
            bookingId: bookingIdResp,
            productType,
            api_key: currentUser?.userToken,
            currency: pgCurrency,
            language_code: getLangCodeFull(),
            origin: getHost(),
            pgType: INSPIRA_PG,
            correlation_id: getCookie("correlationId"),
            org_id: getOrgId()?.org_id,
            service: getOrgId()?.book_service,
            user_id: currentUser?.userId,
            ...(productType === "HTL"
              ? {
                  isPriceChange: extraMetadataHLTPriceHange?.isPriceChange
                    ? true
                    : false,
                  room_id: extraMetadataHLTPriceHange?.room_id
                    ? extraMetadataHLTPriceHange?.room_id
                    : undefined,
                  booking_ref_no: extraMetadataHLTPriceHange?.booking_ref_no
                    ? extraMetadataHLTPriceHange?.booking_ref_no
                    : undefined,
                  isSelectDiffRoom: extraMetadataHLTPriceHange?.isSelectDiffRoom
                    ? true
                    : false,
                }
              : {}),
            ...(productType === "CAR"
              ? {
                  prebook: true,
                  cc_required,
                }
              : {}),
            ...(productType === "SUB"
              ? {
                  charge_org,
                  is_renewal,
                  is_trial,
                  ...(debt_payed ? { debt_payed } : {}),
                }
              : {}),
            ...(productType === "STY" && staysPayLater === true
              ? {
                  staysPayLater,
                }
              : {}),
          },
        };

        await loggerServer({
          dataRequest: metadataUpdate,
          processName: UPDATE_PAY_NEW_CARD,
          url: UPDATE_PAY_NEW_CARD_URL,
        });

        pgSwitchSDK
          .updatePayment(metadataUpdate)
          .then(async (res: any) => {
            console.log(res, "======> log update");
            await loggerServer({
              dataResponse: res,
              processName: UPDATE_PAY_NEW_CARD,
              url: UPDATE_PAY_NEW_CARD_URL,
            });
            await loggerServer({
              dataRequest: {},
              processName: HANDLE_PAY_CLICK_NEW_CARD,
              url: HANDLE_PAY_CLICK_NEW_CARD_URL,
            });
            const result: {
              return_url: string;
              status: boolean;
              message?: string;
            } | null = await pgSwitchSDK.handlePayClick(_e);
            console.log("🚀 ~ updateData >>>>2 ~ result:", result);
            console.log("🚀 ~ updateData >>>>2 ~ paymentId:", paymentId);
            console.log(
              "🚀 ~ updateData >>>>2 ~ bookingIdResp:",
              bookingIdResp
            );
            setBuyButtonSpin(false);
            await loggerServer({
              dataResponse: { ...result },
              processName: HANDLE_PAY_CLICK_NEW_CARD,
              url: HANDLE_PAY_CLICK_NEW_CARD_URL,
            });
            if (result?.return_url && result.status) {
              if (paymentId && bookingIdResp && !preventGoToCheckoutSuccess) {
                console.log(
                  "🚀 ~ handlePayClick ~ paymentId: updatePayment",
                  paymentId,
                  bookingIdResp
                );
                //router.push(result?.return_url + `&booking_id=${bookingIdResp}`);
                //window.location.href = `${result?.return_url}&booking_id=${bookingIdResp}`;
                //localStorage.removeItem("paymentId");
                window.location.href = `${pathSuccess}?booking_id=${bookingIdResp}${extraDataUrl}`;
              }
              if (
                paymentId &&
                bookingIdResp &&
                preventGoToCheckoutSuccess &&
                productType !== "SUB" &&
                executeProccedBooking
              ) {
                executeProccedBooking(Number(bookingIdResp || 0));
              } else if (
                paymentId &&
                bookingIdResp &&
                productType === "SUB" &&
                preventGoToCheckoutSuccess &&
                extraProceed
              ) {
                extraProceed(true);
              }
              setInitPaymentData(null);
              if (productType !== "SUB") {
                setHideBtnBook(false);
              }
              setLoadSaveNewCardForm(false);

              if (forceReload) {
                setTimeout(() => {
                  //localStorage.removeItem("paymentId");
                  router.reload();
                }, 500);
              }
            }

            if (result?.message) {
              setErrorMessage(result.message);
              setBuyButtonSpin(false);

              setTimeout(() => {
                setErrorMessage("");
              }, 5000);
            }
          })
          .catch(async (error: any) => {
            await loggerServer({
              dataErrorResponse: { ...error },
              processName: UPDATE_PAY_NEW_CARD,
              url: UPDATE_PAY_NEW_CARD_URL,
            });
            setBuyButtonSpin(false);
            console.log(error, "error en update");
          });
      } else {
        setBuyButtonSpin(false);
        console.info(
          "🚀 ~ handlePayClick ~ paymentId error: updatePayment:",
          paymentId
        );
      }
    } catch (e) {
      await loggerServer({
        dataErrorResponse: { e },
        processName: UPDATE_PAY_NEW_CARD,
        url: UPDATE_PAY_NEW_CARD_URL,
      });
      setBuyButtonSpin(false);
    }
  };

  async function initPaymentCeroPayLater(
    bookingId: String,
    extraMetadataHLTPriceHange: RequestMultiBookExtraMetadataType | null
  ) {
    if (!pgSwitchSDK) {
      console.info("initPaymentNewCard SDK not initialized");
      return;
    }
    if (!initPaymentData) {
      console.info("initPaymentNewCard paymentInfo not initialized");
      return;
    }
    try {
      const element = document.getElementById(`${nameFormPg}-element`) as any;
      element.innerHTML = "";
      console.info("Initializing SDK...");
      const checkSumRes = await generateCheckSum({
        orderId: "",
        currency: pgCurrency,
        countryCode: getCountryCode(),
        amount: price,
        api_key: apiKeySupplier,
      });
      const host = getHost();
      const paymentElement = document.getElementById(`${nameFormPg}-element`);
      const buttonElement = document.getElementById(`${nameFormPg}-submit`);
      console.info({ extraMetadataHLTPriceHange: extraMetadataHLTPriceHange });

      const dataInit = {
        ip: "127.0.0.1",
        currency: pgCurrency || initPaymentData.currency,
        primary_residence: initPaymentData.country_code,
        country_code: initPaymentData.country_code,
        amount: price,
        email: initPaymentData.email,
        return_url: `${host}${pathSuccess}`,
        checksum: checkSumRes || "",
        description: descripcionPayment
          ? descripcionPayment
          : `${productType} book: ${bookingId}`,
        metadata: {
          bookingId,
          productType,
          api_key: currentUser?.userToken,
          currency: pgCurrency,
          language_code: getLangCodeFull(),
          origin: host,
          pgType: INSPIRA_PG,
          correlation_id: getCookie("correlationId"),
          org_id: getOrgId()?.org_id,
          service: getOrgId()?.book_service,
          user_id: currentUser?.userId,
          ...(productType === "HTL"
            ? {
                isPriceChange: extraMetadataHLTPriceHange?.isPriceChange
                  ? true
                  : false,
                room_id: extraMetadataHLTPriceHange?.room_id
                  ? extraMetadataHLTPriceHange?.room_id
                  : undefined,
                booking_ref_no: extraMetadataHLTPriceHange?.booking_ref_no
                  ? extraMetadataHLTPriceHange?.booking_ref_no
                  : undefined,
                isSelectDiffRoom: extraMetadataHLTPriceHange?.isSelectDiffRoom
                  ? true
                  : false,
              }
            : {}),
          ...(productType === "CAR"
            ? {
                prebook: true,
                cc_required: false,
              }
            : {}),
          ...(productType === "SUB"
            ? {
                charge_org,
                is_renewal,
                is_trial,
                ...(debt_payed ? { debt_payed } : {}),
              }
            : {}),
          ...(productType === "STY" && staysPayLater === true
            ? {
                staysPayLater,
              }
            : {}),
        },
      };
      await loggerServer({
        dataRequest: { ...dataInit },
        processName: INIT_CERO_PAY_LATER,
        url: INIT_CERO_PAY_LATER,
      });

      const init = await pgSwitchSDK.init(
        paymentElement,
        buttonElement,
        dataInit
      );
      await loggerServer({
        dataResponse: init,
        processName: INIT_CERO_PAY_LATER,
        url: INIT_CERO_PAY_LATER,
      });
      console.log("🚀 ~ init >>>>:", init);
      if (
        init &&
        init.provider_credential.client_token === undefined &&
        init.payment_id &&
        init?.return_url
      ) {
        const updateData = {
          payment_id: init.payment_id,
          order_id: bookingId,
        };
        await loggerServer({
          dataRequest: updateData,
          processName: UPDATE_CERO_PAY_LATER,
          url: UPDATE_CERO_PAY_LATER_URL,
        });
        const res = await pgSwitchSDK.updatePayment(updateData);
        await loggerServer({
          dataResponse: res,
          processName: UPDATE_CERO_PAY_LATER,
          url: UPDATE_CERO_PAY_LATER_URL,
        });
        if (!preventGoToCheckoutSuccess) {
          window.location.href = `${pathSuccess}?booking_id=${bookingId}${extraDataUrl}`;
        }
        if (
          preventGoToCheckoutSuccess &&
          productType !== "SUB" &&
          executeProccedBooking
        ) {
          executeProccedBooking(Number(bookingId || 0));
        } else if (
          productType === "SUB" &&
          preventGoToCheckoutSuccess &&
          extraProceed
        ) {
          extraProceed(true);
        }
      }
      setBuyButtonSpin(false);
    } catch (e) {
      console.log("Error during initPaymentNewCard initialization: ", e);
      setBuyButtonSpin(false);
    }
  }

  async function handlePayClick(e: any) {
    setBuyButtonSpin(true);
    initBook(e, true)
      .then((bookingId) => {
        if (
          bookingId &&
          !bookingId.includes("undefined") &&
          !bookingId.includes("NaN")
        ) {
          if (price > 0) {
            updateData(e, bookingId);
          } else {
            initPaymentCeroPayLater(
              bookingId || "",
              extraMetadataHLTPriceHange
            );
          }
        } else {
          setBuyButtonSpin(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setBuyButtonSpin(false);
      });
  }
  const checkInInstrucctions = (label: string) => {
    const splitText = label.split(" ");
    const lastWord = splitText[splitText.length - 1];
    const textWithoutLastWord = splitText.slice(0, -1).join(" ");
    return (
      <>
        {lastWord.toLocaleLowerCase() === "zu" ? (
          <>
            <span className="checkouthotel-link">{textWithoutLastWord}</span>
            <span
              className="tw-font-normal tw-no-underline lastwordClass"
              style={{ textDecoration: "none !important" }}
            >
              {" "}
              {lastWord}
            </span>
          </>
        ) : (
          <span className="checkouthotel-link">{label}</span>
        )}
      </>
    );
  };
  useEffect(() => {
    if (paymentInfo && isCustomerLoaded && isVisible) {
      if (selectedCreditCard == NEW_CARD) {
        setHideBtnBook(true);

        setIsSavedCard(true);
        const timer = setTimeout(() => {
          initSaveCard();
        }, 300);
        return () => clearTimeout(timer);
      }
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomerLoaded, isVisible]);

  // useEffect(() => {
  //   if (hideCardRequired) {
  //     creditCard();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [hideCardRequired]);

  useEffect(
    () => {
      if (isCustomerLoaded && isVisible) {
        if (selectedCreditCard == PAY_WITH_NEW_CARD && iframe !== "iframe") {
          if (price > 0) {
            setEnableButtonPay(true);
            initPaymentNewCard(extraMetadataHLTPriceHange, price);
            setLoadSaveNewCardForm(true);
          }
          setIsSavedCard(false);
          setHideBtnBook(true);
        } else if (iframe === "iframe") {
          setHideBtnBook(true);
          setIsSavedCard(false);
          setCc_required(true);
          setHideCardRequired(true);
          creditCard();
        }
        //setIsSavedCard(false);
        //setHideBtnBook(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isCustomerLoaded,
      //selectedCreditCard,
      extraMetadataHLTPriceHange,
      price,
      isVisible,
      iframe,
    ]
  );

  useEffect(() => {
    if (savedCard) {
      if (creditCardsOptions) {
        console.log("🚀 ~ useEffect ~ savedCard3:", savedCard);
        const lastCard = creditCardsOptions[creditCardsOptions.length - 1];
        setSelectedCreditCard(lastCard.value);
        setTimeout(() => {
          setSavedCard(false);
        }, 500);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedCard, creditCardsOptions]);

  useEffect(() => {
    if (
      selectedCreditCard !== NEW_CARD &&
      selectedCreditCard !== PAY_WITH_NEW_CARD
    ) {
      setHideBtnBook(false);
      setIsVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCreditCard]);

  useEffect(() => {
    if (
      selectedCreditCard == NEW_CARD ||
      selectedCreditCard == PAY_WITH_NEW_CARD
    ) {
      setIsVisible(false);
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [selectedCreditCard]);

  if (!isVisible && productType !== "SUB") {
    return <></>;
  }

  return (
    <>
      {isVisible && (
        <>
          <form id="save-card-form">
            <MainContainer
              theme={{
                show: loadSaveNewCardForm && isSavedCard && !hideCardRequired,
              }}
              fluid
            >
              <Row>
                <Col>
                  <Title className="payment-methods-main">
                    {"Save New Card"}
                  </Title>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="w-100 pt-0">
                    <div id="save-card-element"></div>
                    <div className="text-center">
                      <button
                        className="btn btn-primary mb-2 mt-2"
                        id="save-card-submit"
                        type="button"
                        disabled={savedCardSpin}
                      >
                        <div
                          className="spinner hidden"
                          id="save-card-spinner"
                        ></div>
                        <span id="save-card-button-text">
                          {"Save New Card"} <LoaderSpin show={savedCardSpin} />
                        </span>
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </MainContainer>
          </form>

          {/**Cambios de pablo  */}
          <MainContainer theme={{ show: hideCardRequired }} fluid>
            <Row>
              <Col>
                <Title className="payment-methods-main">{"Credit Card"}</Title>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="w-100 pt-0">
                  <div id="credit-card-element" className="max-w-[100%]"></div>
                </div>
              </Col>
            </Row>
          </MainContainer>
          <ContainerButton
            fluid
            className="complete-booking-main mb-4"
            theme={{
              show: hideCardRequired,
            }}
          >
            <RowDivButton>
              <ColDiv>
                {getLangLabel(
                  "BE SELECTING TO COMPLETE THIS BOOKING I ACKNOWLEDGE THAT I HAVE READ AND ACCEPT THE"
                )}{" "}
                <Span
                  className="checkouthotel-link"
                  onClick={() => window.open("/privacy-policy", "_blank")}
                >
                  {getLangLabel("Privacy Policy")}
                </Span>
                {", "}
                <Span
                  className="checkouthotel-link"
                  onClick={() => window.open("/terms-of-use", "_blank")}
                >
                  {getLangLabel("Rules and Restrictions")}
                </Span>
                {productType !== "SUB" && productType !== "WLT" && (
                  <>
                    {", "}
                    <Span className="checkouthotel-link" onClick={() => {}}>
                      {getLangLabel("Cancellation Terms")}
                    </Span>{" "}
                    {getLangLabel("and")}{" "}
                    <Span className="checkouthotel-link" onClick={() => {}}>
                      {getLangLabel("Check-in Instructions")}
                    </Span>
                  </>
                )}
              </ColDiv>
            </RowDivButton>
            <RowDiv className="justify-content-center row-btn-booking">
              <Col>
                <div className="tw-flex tw-gap-2 tw-justify-around tw-items-center lg:tw-mt-0 tw-mt-8 tw-mb-8 tw-bg-white">
                  <h1 className="lg:tw-hidden tw-font-semibold md:tw-text-[1.5rem] tw-text-[1rem] tw-text-inspira-purple-500">
                    Total: ${price}
                  </h1>
                  {setModalDetailsShow && (
                    <div
                      key={`button-credit-newpg-${productType}`}
                      className="lg:tw-hidden tw-font-semibold md:tw-text-[1.5rem] tw-text-[1rem] tw-text-inspira-purple-500 tw-cursor-pointer"
                      onClick={() => setModalDetailsShow()}
                    >
                      {getLangLabel("details")}
                      {">"}
                    </div>
                  )}
                  <Btn
                    hidden={!loadButtonCC}
                    className="btn-complete-booking"
                    variant="light"
                    id="credit-card-submit"
                    onClick={(e: any) => {
                      setCreditCardSpin(true);
                      initBook(e, true)
                        .then((bookingId) => {
                          if (
                            bookingId &&
                            !bookingId.includes("undefined") &&
                            !bookingId.includes("NaN")
                          ) {
                            passCreditCard(bookingId);
                          } else {
                            setCreditCardSpin(false);
                          }
                        })
                        .catch((error) => {
                          setCreditCardSpin(false);
                          console.error("Error:", error);
                        });
                    }}
                    type="button"
                  >
                    {getLangLabel(buttonLabel)}
                    <LoaderSpin show={creditCardSpin} />
                  </Btn>
                </div>
              </Col>
            </RowDiv>
          </ContainerButton>
          {/** Cambios de pablo */}

          {productType !== "SUB" && (
            <form id={`${nameFormPg}-form`}>
              <MainContainer
                theme={{
                  show:
                    loadSaveNewCardForm &&
                    price > 0 &&
                    !isSavedCard &&
                    !hideCardRequired,
                }}
                fluid
              >
                <Row>
                  <Col>
                    <Title className="payment-methods-main">
                      {getLangLabel("Payment")}
                    </Title>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="w-100 box-shadow">
                      <div className="w-100">
                        <div id={`${nameFormPg}-element`}></div>
                      </div>
                    </div>
                    {errorMessage && (
                      <div className="text-danger text-left mt-2">
                        {getLangLabel(errorMessage)}
                      </div>
                    )}
                  </Col>
                </Row>
              </MainContainer>
              <div
                className={` tw-bg-white tw-text-left tw-rounded-[15px] tw-mt-8 tw-pt-8 tw-mb-[12rem] tw-pb-8 tw-shadow-[0_0_10px_0_#ccc] tw-z-[1] md:tw-mb-48 lg:tw-hidden`}
              >
                <ColDiv>
                  {`${getLangLabel(
                    "BE SELECTING TO COMPLETE THIS BOOKING I ACKNOWLEDGE THAT I HAVE READ AND ACCEPT THE"
                  )}${productType == "FLT" ? `` : ` `}`}
                  <TermsOfBooking
                    theme={{ show: productType == "FLT" }}
                    className="TermsOfBookingClass"
                  >
                    <TermsAndConditionsLink
                      className="TermsAndConditionsLink"
                      href="/terms-of-use"
                      target="_blank"
                      theme={{ notApplyRightSpace: true }}
                    >{`${getLangLabel(
                      "TERMS OF USE"
                    )}`}</TermsAndConditionsLink>
                    <TermsBookingSpan>{`, `}</TermsBookingSpan>
                    <TermsAndConditionsNoLink
                      onClick={() => {
                        if (handleSetShowTermsAndConditions) {
                          handleSetShowTermsAndConditions();
                        }
                      }}
                    >{`${getLangLabel(
                      "FLIGHT SPECIAL TERMS"
                    )}`}</TermsAndConditionsNoLink>
                    <TermsBookingSpan>{`,`}</TermsBookingSpan>
                    <TermsAndConditionsNoLink
                      onClick={() => {
                        if (handleShowCancellationPolicy) {
                          handleShowCancellationPolicy();
                        }
                      }}
                    >{` ${getLangLabel(
                      "AIRLINE CANCELLATION POLICY"
                    )}`}</TermsAndConditionsNoLink>
                    <TermsBookingSpan>{` ${getLangLabel(
                      "AND ITS"
                    )}`}</TermsBookingSpan>
                    <TermsAndConditionsLink
                      className="TermsAndConditionsLink"
                      href="/privacy-policy"
                      target="_blank"
                    >{`${getLangLabel(
                      "PRIVACY POLICY"
                    )}`}</TermsAndConditionsLink>
                  </TermsOfBooking>
                  <TermsOfBooking
                    theme={{ show: productType != "FLT" }}
                    className="TermsOfBookingClass"
                  >
                    <Span
                      className="checkouthotel-link line-underneath-txt"
                      onClick={() => window.open("/privacy-policy", "_blank")}
                    >
                      {getLangLabel("Privacy Policy")}
                    </Span>
                    {", "}
                    <Span
                      className="checkouthotel-link line-underneath-txt"
                      onClick={() =>
                        handleSetShowRules
                          ? handleSetShowRules()
                          : window.open("/terms-of-use", "_blank")
                      }
                    >
                      {getLangLabel("Rules and Restrictions")}
                    </Span>
                    {productType !== "WLT" && (
                      <>
                        {", "}
                        {handleShowCancellationPolicy && (
                          <Span
                            className="checkouthotel-link line-underneath-txt"
                            onClick={() => handleShowCancellationPolicy()}
                          >
                            {getLangLabel("Cancellation Terms")}
                          </Span>
                        )}{" "}
                        {handleSetShowCheckInInstructios && (
                          <>
                            {" "}
                            {getLangLabel("and")}{" "}
                            <Span
                              className="checkouthotel-link line-underneath-txt"
                              onClick={() => handleSetShowCheckInInstructios()}
                            >
                              {getLangLabel("Check-in Instructions")}
                            </Span>
                          </>
                        )}
                      </>
                    )}
                  </TermsOfBooking>
                </ColDiv>
              </div>

              <ContainerButton
                fluid
                className="complete-booking-main mb-4 TermsOfBookingClassContainer"
                theme={{
                  show:
                    (loadSaveNewCardForm || price == 0) &&
                    !isSavedCard &&
                    !hideCardRequired,
                }}
              >
                <RowDivButton className="TermsOfBookingClassRow">
                  <ColDiv className="TermsOfBookingClassCol">
                    {`${getLangLabel(
                      "BE SELECTING TO COMPLETE THIS BOOKING I ACKNOWLEDGE THAT I HAVE READ AND ACCEPT THE"
                    )}${productType == "FLT" ? `` : ` `}`}
                    <TermsOfBooking
                      theme={{ show: productType == "FLT" }}
                      className="TermsOfBookingClass"
                    >
                      <TermsAndConditionsLink
                        className="TermsAndConditionsLink"
                        href="/terms-of-use"
                        target="_blank"
                        theme={{ notApplyRightSpace: true }}
                      >{`${getLangLabel(
                        "TERMS OF USE"
                      )}`}</TermsAndConditionsLink>
                      <TermsBookingSpan>{`, `}</TermsBookingSpan>
                      <TermsAndConditionsNoLink
                        onClick={() => {
                          if (handleSetShowTermsAndConditions) {
                            handleSetShowTermsAndConditions();
                          }
                        }}
                      >{`${getLangLabel(
                        "FLIGHT SPECIAL TERMS"
                      )}`}</TermsAndConditionsNoLink>
                      <TermsBookingSpan>{`,`}</TermsBookingSpan>
                      <TermsAndConditionsNoLink
                        onClick={() => {
                          if (handleShowCancellationPolicy) {
                            handleShowCancellationPolicy();
                          }
                        }}
                      >{` ${getLangLabel(
                        "AIRLINE CANCELLATION POLICY"
                      )}`}</TermsAndConditionsNoLink>
                      <TermsBookingSpan>{` ${getLangLabel(
                        "AND ITS"
                      )}`}</TermsBookingSpan>
                      <TermsAndConditionsLink
                        className="TermsAndConditionsLink"
                        href="/privacy-policy"
                        target="_blank"
                      >{`${getLangLabel(
                        "PRIVACY POLICY"
                      )}`}</TermsAndConditionsLink>
                    </TermsOfBooking>
                    <TermsOfBooking
                      theme={{ show: productType != "FLT" }}
                      className="TermsOfBookingClass"
                    >
                      <Span
                        className="checkouthotel-link line-underneath-txt"
                        onClick={() => window.open("/privacy-policy", "_blank")}
                      >
                        {getLangLabel("Privacy Policy")}
                      </Span>
                      {handleShowCancellationPolicy &&
                      handleSetShowCheckInInstructios
                        ? ", "
                        : ` ${getLangLabel("and")} `}
                      <Span
                        className=""
                        onClick={() =>
                          handleSetShowRules
                            ? handleSetShowRules()
                            : window.open("/terms-of-use", "_blank")
                        }
                      >
                        {checkInInstrucctions(
                          getLangLabel("Rules and Restrictions")
                        )}
                      </Span>
                      {productType !== "WLT" && (
                        <>
                          {handleShowCancellationPolicy && (
                            <Span
                              className="checkouthotel-link line-underneath-txt"
                              onClick={() => handleShowCancellationPolicy()}
                            >
                              {", "}
                              {getLangLabel("Cancellation Terms")}
                            </Span>
                          )}{" "}
                          {handleSetShowCheckInInstructios && (
                            <>
                              {" "}
                              {getLangLabel("and")}{" "}
                              <Span
                                className=""
                                onClick={() =>
                                  handleSetShowCheckInInstructios()
                                }
                              >
                                {checkInInstrucctions(
                                  getLangLabel("Check-in Instructions")
                                )}
                              </Span>
                              .
                            </>
                          )}
                        </>
                      )}
                    </TermsOfBooking>
                  </ColDiv>
                </RowDivButton>
                <RowDiv className="justify-content-center row-btn-booking tw-z-50">
                  <Col>
                    <div className="tw-flex tw-gap-2 tw-justify-around tw-items-center lg:tw-mt-0 tw-mt-8 tw-mb-8 tw-bg-white">
                      <h1 className="lg:tw-hidden tw-font-semibold md:tw-text-[1.5rem] tw-text-[1rem] tw-text-inspira-purple-500">
                        Total: {getSymb()}
                        {price}
                      </h1>
                      {setModalDetailsShow && (
                        <div
                          key={`button-pay-newpg-${productType}`}
                          className="lg:tw-hidden tw-font-semibold md:tw-text-[1.5rem] tw-text-[1rem] tw-text-inspira-purple-500 tw-cursor-pointer"
                          onClick={() => setModalDetailsShow()}
                        >
                          {getLangLabel("details")}
                          {">"}
                        </div>
                      )}
                      <Btn
                        className={`btn-complete-booking`}
                        variant="light"
                        id={`${nameFormPg}-submit`}
                        disabled={
                          buyButtonSpin || enableButtonPay || disabledButton
                        }
                        onClick={(e: any) => {
                          handlePayClick(e);
                        }}
                      >
                        {getLangLabel(buttonLabel)}{" "}
                        <LoaderSpin show={buyButtonSpin} />
                      </Btn>
                    </div>
                  </Col>
                </RowDiv>
              </ContainerButton>
              <ModalStripeElementsProccessCardFail
                show={showStripeElementsCardFail}
                isMobile={breakpoints.down("lg")}
                handleCloseModal={handleSetShowStripeElementsCardFail}
              />
            </form>
          )}
        </>
      )}

      {productType === "SUB" && (
        <form id={`${nameFormPg}-form`}>
          {isVisible && selectedCreditCard === PAY_WITH_NEW_CARD && (
            <div
              className={`tw-mt-8 tw-w-full tw-bg-white tw-shadow-2xl tw-rounded-2xl tw-px-4 tw-py-4 lg:tw-px-20 lg:tw-py-8 ${
                price == 0 ? `tw-hidden` : `tw-block`
              }`}
            >
              <Row>
                <Col>
                  <Title className="payment-methods-main">
                    {getLangLabel("Payment")}
                  </Title>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="w-100 box-shadow">
                    <div className="w-100">
                      <div id={`${nameFormPg}-element`}></div>
                    </div>
                  </div>
                  {errorMessage && (
                    <div className="text-danger text-left mt-2">
                      {getLangLabel(errorMessage)}
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          )}

          <div className="tw-mt-8 tw-w-full tw-bg-white tw-shadow-2xl tw-rounded-2xl tw-px-4 tw-py-4 lg:tw-px-20 lg:tw-py-8 tw-flex tw-items-center tw-justify-center">
            <div className="tw-flex-1">{children}</div>
            <div className="tw-flex-1 tw-border-l-[1px]">
              <div className="tw-flex tw-items-center tw-justify-center">
                <button
                  className={`tw-px-8 tw-py-2
                      ${
                        disabledButton || isDisabledForMemberShip
                          ? ""
                          : "tw-border-black"
                      } 
                    ${
                      disabledButton || isDisabledForMemberShip
                        ? "tw-bg-[#595959]"
                        : "tw-bg-black"
                    } tw-text-white tw-rounded-3xl 
                    ${
                      disabledButton || isDisabledForMemberShip
                        ? ""
                        : "hover:tw-text-black"
                    }
                    ${
                      disabledButton || isDisabledForMemberShip
                        ? ""
                        : "hover:tw-bg-white"
                    } tw-border-[2px] tw-font-semibold ${
                    disabledButton || isDisabledForMemberShip
                      ? "tw-cursor-not-allowed"
                      : "tw-cursor-pointer"
                  }`}
                  id={`${nameFormPg}-submit`}
                  disabled={
                    buyButtonSpin ||
                    enableButtonPay ||
                    disabledButton ||
                    isDisabledForMemberShip
                  }
                  onClick={(e: any) => {
                    handlePayClick(e);
                  }}
                >
                  {getLangLabel(buttonLabel)}{" "}
                  <LoaderSpin show={buyButtonSpin} />
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default InspiraPg;
